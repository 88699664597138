import { useEffect,useState,useRef } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Link from "@material-ui/core/Link";
import { TFunction, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CCRN_HRINTOUCH_URL,CCRN_RELIANCEMATRIX_URL } from "src/constants/external_links";
import { HR_BENEFITS_EMAIL, CCRN_RELIANCEMATRIX_EMAIL } from "src/constants/contact_info";
import { BENEFITS } from "src/router/routes";
import config from "src/config";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import styles from "src/components/common/MainContent/Home/OpenEnrollments.module.scss";

const EnrollmentLists = ({
  t,
  title,
  elements,
  className1,
  className2,
}: {
  t: TFunction<"translation", undefined>;
  title: string;
  elements: string[];
  className1?: string;
  className2?: string;
}) => {
  return (
    <>
      {elements.length === 7 ? (
        <>
          {title !== "" && (
            <>
              <div className={className1}>{t(`enrollment.${title}`)}</div>
              <br />
            </>
          )}
        </>
      ) : (
        <div className={className1}>{t(`enrollment.${title}`)}</div>
      )}
      <div className={className2}>
        <ul>
          {elements.map((element) => (
            <li>
              <span>
                {t(`enrollment.${element}`)}
                {element === "enrollmentEnhancePT7" ? (
                  <>
                    <Link className={styles.benefitLinks} href={`mailto:${HR_BENEFITS_EMAIL}`}>
                      {HR_BENEFITS_EMAIL}
                    </Link>
                    .
                  </>
                ) : (
                  <></>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

EnrollmentLists.defaultProps = {
  className1: styles.marginEnrollment,
  className2: styles.enrollmentPlanDesc,
};

function OpenEnrollment() {
  const { t } = useTranslation();
  const history = useHistory();
  const [ control, setControl ]= useState(false);
  const videoRef =  useRef<HTMLVideoElement | null>(null);

  const navigateToBenefits = () => {
    history.push(BENEFITS);
  };

  const onVedioClick = (e) => {
    e.preventDefault();

    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
    setControl(true);
  };

  const onVideoEnd = (e) => {
    e.preventDefault();
    if (videoRef.current) {
      setControl(false);
      videoRef.current.load();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enrollmentVideo = () => {
    return (
      <Col md={5} lg={5}>
        <div className={styles.openEnrollmentVideoContainer}>
          <video
            controls={control}
            className={styles.openEnrollmentVideo}
            width="100%"
            preload="auto"
            onClick={(e) => onVedioClick(e)}
            poster={config.openEnrollmentVideoImg}
            onEnded={(e) => onVideoEnd(e)}
            ref={videoRef}
          >
            <track kind="captions" />
            <source src={config.openEnrollmentVideoUrl} type="video/mp4" />
          </video>
        </div>

        <Row className={styles.openEnrollSection}>
          <Col xs={6} md={5} lg={4} sm={12} className={styles.openEnrollmentNov2024}>
            <div className={styles.openEnrollment2024}>{t("enrollment.openEnrollment2024")}</div>
          </Col>
          <Col xs={6} md={3} lg={4} sm={12}>
            <div className={styles.openEnrollmentNovText}>{t("enrollment.November1-14")}</div>
          </Col>
          <Col xs={12} md={4} lg={4} sm={12} className={styles.EnrollBtn}>
            <a href={CCRN_HRINTOUCH_URL} target="_blank" rel="noreferrer">
              <Button variant="contained" className={`${styles.enrollButton} ${styles.enrollText}`}>
                {t("enrollment.Enroll")}
              </Button>
            </a>
          </Col>
        </Row>
      </Col>
    );
  };

  const enrollmentHeading = () => {
    return (
      <Col xs={12} md={7} lg={7} sm={12}>
        <div
          className={styles.backToBenefits}
          role="button"
          tabIndex={0}
          onKeyPress={navigateToBenefits}
          onClick={navigateToBenefits}
        >
          {t("enrollment.backToBenefits")}
        </div>
        <div className={styles.enrollmentTitle}>{t("enrollment.enrollmentTitle")}</div>
        <div className={styles.enrollmentDesc}>{t("enrollment.enrollmentContent")}</div>
        <EnrollmentLists
          t={t}
          title="enrollmentEnhance"
          elements={["enrollmentEnhancePT1"]}
          className1={styles.enrollmentTextFont}
          className2={`${styles.beneSubText} ${styles.enrollmentListTextHeading}`}
        />
      </Col>
    );
  };
  return (
    <div>
      <Container fluid className={styles.enrollmentBanner}>
        <Container>
          <p className={styles.enrollmentBannerText}>{t("enrollment.enrollmentBannerTxt")}</p>
        </Container>
      </Container>

      <Container>
        <Row>
          {isMobile ? (
            <>
              {enrollmentVideo()}
              {enrollmentHeading()}
            </>
          ) : (
            <>
              {enrollmentHeading()}
              {enrollmentVideo()}
            </>
          )}
        </Row>
      </Container>
      <Container>
        <EnrollmentLists
          t={t}
          title=""
          elements={[
            "enrollmentEnhancePT2",
            "enrollmentEnhancePT3",
            "enrollmentEnhancePT4",
            "enrollmentEnhancePT5",
            "enrollmentEnhancePT6",
            "enrollmentEnhancePT7",
            "enrollmentEnhancePT8",
          ]}
          className1={styles.enrollmentTextFont}
          className2={`${styles.beneSubText} ${styles.enrollmentListText}`}
        />
      </Container>
      <Container>
        <div className={styles.enrollmentContent}>{t("enrollment.openEnrollmentTxt")}</div>
        <div className={styles.enrollmentTextFontPara}>
          {`${t("enrollment.openEnrollmentContent1")} `}
          <Link className={styles.benefitLinks} href={CCRN_HRINTOUCH_URL} target="_blank">
            {t("enrollment.openEnrollmentLink")}
          </Link>
          {t("enrollment.openEnrollmentContent2")}
        </div>

        <div className={styles.enrollmentContentSecond}>
          {t("enrollment.openEnrollmentContentText1")}
        </div>
        <div className={styles.enrollmentTextFontPara}>
          {`${t("enrollment.openEnrollmentContentText2")} `}
          <br />
          <br />
          {`${t("enrollment.openEnrollmentACI")} `}
          <br />
          {`${t("enrollment.openEnrollmentPhone")} `}
          <Link className={styles.benefitLinks} href={`mailto:${CCRN_RELIANCEMATRIX_EMAIL}`}>
            {CCRN_RELIANCEMATRIX_EMAIL}
          </Link>
          <br />
          <Link className={styles.benefitLinks} href={CCRN_RELIANCEMATRIX_URL} target="_blank">
            {t("enrollment.openEnrollmentMail")}
          </Link>
          <br />
          {`${t("enrollment.openEnrollmentCode")} `}
        </div>

        <div className={`${styles.enrollmentTextFontPara} mt-20`}>
          {t("enrollment.openEnrollmentContent3")}
        </div>

        <div className={`${styles.enrollmentTextFontPara} ${styles.benefitPlan}`}>
          {t("enrollment.openEnrollmentContent4")}
        </div>
      </Container>
    </div>
  );
}

export default OpenEnrollment;
