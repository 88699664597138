import fetch from "cross-fetch";
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import request, { RequestDocument } from "graphql-request";

const graphqlServer = `${process.env.REACT_APP_PUBLIC_API_BASE}/graphql`;
const apolloClient = new ApolloClient({
  link: new HttpLink({ uri: graphqlServer, fetch }),
  cache: new InMemoryCache(),
});

const graphqlRequest = async (document: RequestDocument, variables: any = {}, token?: string | null) =>
  token ? request(graphqlServer, document, variables, { authorization: `Bearer ${token}` }) :
    request(graphqlServer, document, variables)

export { gql, graphqlServer, graphqlRequest, apolloClient };
