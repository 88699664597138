import { makeStyles } from "@material-ui/core/styles";
import NoResultsBrowser from "src/imagesV2/NoResults.svg";
import NoResultsMob from "src/imagesV2/NoResults-mobile.svg";

const useStyles = makeStyles(() => {
  return {
    noResults: {
      backgroundColor: "#f5f6f9",
      marginBottom: "10px",
      display: "inline-flex",
      padding: "1% 1% 1% 3%",
      width: "94%",
      marginLeft: "1%",
      "@media(min-width: 0px) and (max-width: 768px)": {
        marginLeft: "3%",
        border: "1px solid #E0E0E2",
        padding: "0px 10px 0px 0px",
        borderRadius: "5px",
        marginTop: "1rem",
      },
    },
    noResultsCenter: {
      backgroundColor: "#f5f6f9",
      marginBottom: "10px",
      display: "flex",
      padding: "1% 18% 1% 18%",
      marginLeft: "0px",
      "@media(min-width: 0px) and (max-width: 768px)": {
        marginLeft: "3%",
        border: "1px solid #E0E0E2",
        padding: "0px 10px 0px 0px",
        borderRadius: "5px",
        marginTop: "1rem",
      },
    },
    noResultsImgWrapper: {
      "@media(min-width: 0px) and (max-width: 768px)": {
        padding: "12px 0px 2px 0px",
      },
    },
    noResultsTxtWrapper: {
      padding: "1.4rem 0.4rem",
      marginLeft: "40px",
      "@media(min-width: 0px) and (max-width: 768px)": {
        padding: "7% 0%",
        marginLeft: "10px",
      },
    },
    noResultsImg: {
      backgroundImage: `url(${NoResultsBrowser})`,
      backgroundRepeat: "no-repeat",
      height: "93px",
      width: "210px",
      backgroundPosition: "center center",
      "@media(min-width: 0px) and (max-width: 768px)": {
        backgroundImage: `url(${NoResultsMob})`,
        height: "75px",
        width: "100px",
      },
    },
    noResultsTxt: {
      color: "#626C7D",
      fontSize: "1.2rem",
      fontWeight: "bold",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.75rem",
      },
    },
    noResultsSubTxt: {
      color: "#626C7D",
      fontSize: "0.9rem",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.65rem",
      },
    },
  };
});

interface NoResultsPorps {
  isCenter: string;
  noResultsTxt: string;
  noResultsSubTxt: string;
}

export default function NoResults(props: NoResultsPorps) {
  const { isCenter, noResultsTxt, noResultsSubTxt } = props;
  const classes = useStyles();

  return (
    <div className={isCenter ? classes.noResultsCenter : classes.noResults}>
      <div className={classes.noResultsImgWrapper}>
        <div className={classes.noResultsImg} />
      </div>
      <div className={classes.noResultsTxtWrapper}>
        <div className={classes.noResultsTxt}>{noResultsTxt}</div>
        <div className={classes.noResultsSubTxt}>{noResultsSubTxt}</div>
      </div>
    </div>
  );
}
