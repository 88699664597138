import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MidIcon from "../imagesV2/MidIconImg";

export const shiftTypes = [
  {
    label: "Day",
    value: "days",
    startIcon: <WbSunnyOutlinedIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: <WbSunnyOutlinedIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />,
    isActive: false,
  },
  {
    label: "Mid",
    value: "evenings",
    startIcon: <MidIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: <MidIcon fontSize="small" color="#fff" className="filterIcon" />,
    isActive: false,
  },
  {
    label: "Night",
    value: "nights",
    startIcon: <DarkModeOutlinedIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <DarkModeOutlinedIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
];

export const shiftLengths = [
  {
    label: "8hrs",
    value: "8hr",
    startIcon: <AccessTimeIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <AccessTimeIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
  {
    label: "10hrs",
    value: "10hr",
    startIcon: <AccessTimeIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <AccessTimeIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
  {
    label: "12hrs",
    value: "12hr",
    startIcon: <AccessTimeIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <AccessTimeIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
];

export const contractLengths = [
  {
    label: "4-8Wks",
    value: "4-8",
    startIcon: <CalendarTodayOutlinedIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <CalendarTodayOutlinedIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
  {
    label: "9-15Wks",
    value: "9-15",
    startIcon: <CalendarTodayOutlinedIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <CalendarTodayOutlinedIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
  {
    label: "16+Wks",
    value: "16",
    startIcon: <CalendarTodayOutlinedIcon fontSize="small" className="filterIcon" />,
    activeStartIcon: (
      <CalendarTodayOutlinedIcon fontSize="small" style={{ color: "#fff" }} className="filterIcon" />
    ),
    isActive: false,
  },
];
export const facilityType = [
  {
    facilityName: String,
    isActive: false,
  }
];

export const jobDetails = [
  {
    label: "Exclusive",
    value: "exclusive",
    startIcon: <></>,
    activeStartIcon: <></>,
    isActive: false,
  },
  {
    label: "New",
    value: "new",
    startIcon: <></>,
    activeStartIcon: <></>,
    isActive: false,
  },
];
