import config from "../config";

export default function getCompanyFromDomain() {
  const baseURI = document.location.origin;
  let company;
  switch (baseURI) {
    case config.CCN_URL:
      company = config.COMPANY_CCN;
      break;
    case config.CCA_URL:
      company = config.COMPANY_CCA;
      break;
    case config.CCN_PROD_URL:
      company = config.COMPANY_CCN;
      break;
    case config.CCA_PROD_URL:
      company = config.COMPANY_CCA;
      break;
    default:
      company = config.COMPANY_CCN;
      break;
  }
  return company;
}
