import { Component } from "react";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import MailIcon from "@material-ui/icons/Mail";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import SchoolIcon from "@material-ui/icons/School";
import { BrowserView, MobileView } from "react-device-detect";
import { connect } from "react-redux";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ControlledEmailTooltip from "src/components/common/MainContent/JobResults/ControlledEmailTooltip";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import Analytics from "src/services/Analytics";
import eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage"
import {
  EmailRecruiterV2Props,
  reqObjType,
  MyJobsFiltered,
} from "src/components/common/MainContent/JobResults/common/index";
import moment from "moment";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";

interface JobCardIconLabelV2Props {
  job: EmailRecruiterV2Props["job"];
  t: EmailRecruiterV2Props["t"];
  history: EmailRecruiterV2Props["history"];
  allJobs: EmailRecruiterV2Props["allJobs"];
  jobDetails: EmailRecruiterV2Props["jobDetails"];
  similarJobsCarouselItems: EmailRecruiterV2Props["similarJobsCarouselItems"];
  collaboratedJobsCarouselItems: EmailRecruiterV2Props["collaboratedJobsCarouselItems"];
  similarOrViewed: EmailRecruiterV2Props["similarOrViewed"];
  recommended: EmailRecruiterV2Props["recommended"];
  viewMyJobs: EmailRecruiterV2Props["viewMyJobs"];
  myJobs: EmailRecruiterV2Props["myJobs"];
  recommendedJobs: EmailRecruiterV2Props["recommendedJobs"];
  isRecommendedJobsOnResult: EmailRecruiterV2Props["isRecommendedJobsOnResult"];
  sourceType: EmailRecruiterV2Props["sourceType"];
  campaignCode: EmailRecruiterV2Props["campaignCode"];
  handleEmailClick: (jobId: string) => void;
  postTheJobApplicationFavoriteEmail: (reqObj: reqObjType & { jobId: string }) => void;
  error: boolean;
  emailSuccess: boolean;
  emailSuccessResponse: {
    appliedJobId: string;
    message: string;
    status: number;
    statusCode: string;
  };
  actionJobId: string;
  filteredJobs: MyJobsFiltered;
  jobsSource: RecommendedJobsSourceTypes;
}

interface JobCardIconLabelV2State {
  open: boolean;
  showFacilityText: boolean;
  favMessage: string;
}

class JobCardIconLabelV2 extends Component<
  JobCardIconLabelV2Props & RouteComponentProps,
  JobCardIconLabelV2State
> {
  constructor(props: JobCardIconLabelV2Props & RouteComponentProps) {
    super(props);

    const { job, t } = this.props;

    this.state = {
      open: false,
      showFacilityText: false,
      favMessage: job.isFavorite ? t("jobs.jobFavorite.removeFav") : t("jobs.jobFavorite.addFav"),
    };
  }

  handleFavoriteButton() {
    const { job, t } = this.props;
    if (job) {
      if (!job.isFavorite) {
        this.setState({
          favMessage: t("jobs.jobFavorite.addedToFav"),
        });
        setTimeout(() => {
          this.setState({
            favMessage: t("jobs.jobFavorite.removeFav"),
          });
        }, 1000);
      } else {
        this.setState({
          favMessage: t("jobs.jobFavorite.removedFav"),
        });
        setTimeout(() => {
          this.setState({
            favMessage: t("jobs.jobFavorite.addFav"),
          });
        }, 1000);
      }
    }
  }

  handleIconClick = (event) => {
    event.stopPropagation();
  };

  handleClose() {
    this.setState({ open: false });
  }

  handleClick() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  trackSegmentEvent = (eventName, props) => {
    const { job } = this.props;
    const eligibilityPercentage = eligibilityPercentageCalc(job)
    const segmentEventName = eventName;
    const segmentEventProps = {
      jobId: props.job.atsJobId,
      origin: document.referrer,
      profession: props.job.professionCode,
      specialty: props.job.specialtyCode,
      state: props.job.state,
      city: props.job.city,
      zip: props.job.zip,
      shift: props.job.shift,
      type: props.job.type,
      weeklyPay: props.job.salaryMax,
      facilityName: props.job.facilityName,
      sourceType: props.sourceType,
      sourceSubArea: props.campaignCode,
      eligibilityPercent: eligibilityPercentage,
    
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  showFacilityText = (val) => {
    this.setState({
      showFacilityText: val,
    });
  };

  dateDiff = (job) => {
    const date1 = moment(moment.utc().format("MMMM DD, YYYY"));
    const date2 = moment(moment.utc(job.datePosted).format("MMMM DD, YYYY"));
    const diff = date1.diff(date2, "days");
    return { diff };
  };

  favoriteJobClick = (val, jobId) => {
    const {
      allJobs,
      jobDetails,
      similarJobsCarouselItems,
      collaboratedJobsCarouselItems,
      similarOrViewed,
      recommended,
      viewMyJobs,
      myJobs,
      recommendedJobs,
      isRecommendedJobsOnResult,
      postTheJobApplicationFavoriteEmail,
      t,
    } = this.props;
    const reqObj = {
      jobId,
      action: "favorite",
      userId: 2,
      request: {
        isActive: val,
      },
      jobs: allJobs,
      jobDetails,
      similarJobs: similarJobsCarouselItems,
      collaboratedJobs: collaboratedJobsCarouselItems,
      similarOrViewed,
      recommended,
      viewMyJobs,
      myJobs,
      recommendedJobs,
      isRecommendedJobsOnResult,
    };
    postTheJobApplicationFavoriteEmail(reqObj);
    setTimeout(() => {
      this.handleFavoriteButton();
    }, 200);
    if (val) {
      const gaEvent = {
        category: t("googleAnalytics.category.jobs"),
        action: t("googleAnalytics.action.favoriteJob"),
        label: t("googleAnalytics.label.favoriteJob"),
      };
      const segmentEventName = t("segment.favoriteJob");
      this.trackEvent(gaEvent, this.props);
      this.trackSegmentEvent(segmentEventName, this.props);
    }
  };

  trackEvent(event, props) {
    const { history } = this.props;
    const dimensions = {
      dimension7: props.job.atsJobId,
      dimension9: props.job.specialtyCode,
      dimension10: props.campaignCode,
    };
    Analytics.sendPageview(history.location, dimensions);
    Analytics.sendEvent(event);
  }

  newChip() {
    const { t } = this.props;
    return (
      <Chip
        size="medium"
        label={t("jobs.jobDescription.newLabel")}
        className="newLabel"
        onClick={this.handleIconClick}
      />
    );
  }

  teachingChipMob() {
    const { t } = this.props;
    return (
      <Chip
        icon={<SchoolIcon />}
        size="medium"
        label={t("jobs.jobDescription.teachingFacility")}
        className="teachingFacilityMob "
      />
    );
  }

  teachingChip() {
    const { t } = this.props;
    const { showFacilityText } = this.state;
    return (
      <IconButton
        size="small"
        className="removePointerEvents"
        onMouseEnter={() => this.showFacilityText(true)}
        onMouseLeave={() => this.showFacilityText(false)}
        onClick={this.handleIconClick}
      >
        <SchoolIcon fontSize="small" className="teachingFacility" />
        <span className={showFacilityText ? "showFacilityText" : "hideFacilityText"}>
          {t("jobs.jobDescription.teachingFacility")}
        </span>
      </IconButton>
    );
  }

  quickOfferChip() {
    const { t } = this.props;
    return <Chip size="medium" label={t("jobCardV2.quickOffer")} className="quickOfferLabel" />;
  }

  exclusiveChip() {
    const { t } = this.props;
    return (
      <Chip
        size="medium"
        label={t("jobs.jobDescription.exclusive")}
        className="exclusiveLabel"
        onClick={this.handleIconClick}
      />
    );
  }

  renderEmailFeedbackTooltip() {
    const { handleEmailClick, error, emailSuccess, job, emailSuccessResponse, actionJobId } =
      this.props;
    return (
      <ControlledEmailTooltip
        handleEmailClick={handleEmailClick}
        error={error}
        success={emailSuccess}
        job={job}
        emailSuccessResponse={emailSuccessResponse}
        actionJobId={actionJobId}
      />
    );
  }

  renderDefaultEmailTooltip() {
    const { t, handleEmailClick, job } = this.props;
    return (
      <Tooltip
        title={t("jobs.emailRecruiter.contactRecruiter")!}
        aria-label="Recruiter"
        placement="top"
      >
        <IconButton
          component="button"
          role="button"
          tabIndex={0}
          size="small"
          onKeyDown={() => handleEmailClick(job.id)}
          onClick={() => handleEmailClick(job.id)}
        >
          {job.isEmailed ? (
            <MailIcon className="headerIcon recruiterIconFilled label-root" />
          ) : (
            <MailOutlinedIcon className="headerIcon recruiterIcon label-root" />
          )}
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    const { job, emailSuccess, error, actionJobId, filteredJobs } = this.props;
    const { favMessage } = this.state;
    let mspJob;
    let preApprovedJob;
    let teachingJob;
    let newJob;
    let teachingJobMob;
    let quickOffer;
    if (job.msp === "Y") mspJob = this.exclusiveChip();
    if (job.isIvs === "Y") quickOffer = this.quickOfferChip();
    if (job.type === "Teaching") {
      teachingJob = this.teachingChip();
      teachingJobMob = this.teachingChipMob();
    }
    const dateDiff = this.dateDiff(job);
    if (dateDiff.diff <= 1) newJob = this.newChip();
    let canFavoriteAndMessage = true;
    if (
      filteredJobs &&
      (filteredJobs.quickApply._embedded.results.find((el) => String(job.id) === el.id) ||
        filteredJobs.assignments._embedded.results.find((el) => String(job.id) === el.id))
    ) {
      canFavoriteAndMessage = false;
    }
    return (
      <div className="buttonEx">
        <BrowserView>
          {quickOffer}
          {preApprovedJob}
          {mspJob}
          {newJob}
          {teachingJob}
        </BrowserView>
        <MobileView>
          {quickOffer}
          {mspJob}
          {newJob}
          {teachingJobMob}
        </MobileView>
        {canFavoriteAndMessage && (
          <div
            role="button"
            tabIndex={0}
            className="jobCardHeaderIcon"
            onKeyDown={this.handleIconClick}
            onClick={this.handleIconClick}
          >
            {(emailSuccess || error) && actionJobId === job.id
              ? this.renderEmailFeedbackTooltip()
              : this.renderDefaultEmailTooltip()}

            <Tooltip title={favMessage} aria-label="favorites" placement="top">
              {job.isFavorite ? (
                <IconButton
                  size="small"
                  className="iconMarginV2"
                  onClick={() => this.favoriteJobClick(false, job.id)}
                >
                  <FavoriteIcon
                    component="svg"
                    className="headerIcon favoriteIcon label-root FavoriteIconclick"
                  />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  className="iconMarginV2"
                  onClick={() => this.favoriteJobClick(true, job.id)}
                >
                  <FavoriteBorderOutlinedIcon
                    component="svg"
                    className="headerIcon favoriteIcon label-root"
                  />
                </IconButton>
              )}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emailSuccess: state.emailRecruiter.emailSuccess,
  emailSuccessResponse: state.emailRecruiter.emailSuccessResponse,
  error: state.emailRecruiter.error,
  jobFavoriteSuccess: state.applyFavEmail.jobFavoriteSuccess,
  allJobs: state.jobResults.jobs,
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobs: state.jobs.recommendedJobs,
  jobDetails: state.jobDetails.jobDetails,
  myJobs: state.myJobs.myJobs,
  filteredJobs: state.myJobs.filteredJobs,
  actionJobId: state.emailRecruiter.actionJobId,
});

const mapDispatchToProps = (dispatch) => ({
  postTheJobApplicationFavoriteEmail: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(JobCardIconLabelV2)));
