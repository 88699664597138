import { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { makeStyles } from "@material-ui/core/styles";
import StepButton from "@material-ui/core/StepButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import BasicInfoIcon from "@material-ui/icons/AccountBoxRounded";
import SpecialtyEduIcon from "@material-ui/icons/SchoolRounded";
import WorkExpIcon from "@material-ui/icons/BusinessCenterRounded";
import SkillsIcon from "@material-ui/icons/EmojiEventsRounded";
import DoneIcon from "@material-ui/icons/Done";
import { useTranslation, withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  CERTIFICATIONS_LICENSES,
  REFERENCES_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
} from "src/router/routes";
import { updateActiveFlag } from "src/redux/onBoarding/action";
import config from "src/config";
import clsx from "clsx";

const enableDashboardAdvertisement = config.Dashboard_ADVERTISEMENT === "true";
const enableDashboardAd = enableDashboardAdvertisement && isMobile
makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const getSteps = () => [
  "Basic Information: - 2 mins",
  "Specialties & Education: - 3 mins",
  "Work Experience: - 5 mins",
  "References: - 2 mins",
  "Skills Checklist: - 15 mins",
];

interface MyProfileNavProps {
  username: string;
  onBoardingCompletedSteps: { [key: number]: boolean };
  onBoardingActiveSteps: string;
  updateTheActiveFlag: (activeFlag: number) => void;
  lastVisitedPage: string;
}

function MyProfileNav({
  username,
  onBoardingCompletedSteps,
  onBoardingActiveSteps,
  updateTheActiveFlag,
  lastVisitedPage,
}: MyProfileNavProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const steps = getSteps();
  const currentStep = +onBoardingActiveSteps;
  const [activeStep, setActiveStep] = useState(currentStep === -1 ? 0 : currentStep);
  const [completed, setCompleted] = useState(onBoardingCompletedSteps);
  const totalSteps = steps.length;
  const completedSteps = Object.keys(completed).length;

  const setActiveStepValue = (step) => {
    setActiveStep(step);
  };

  const updateReduxActiveFlagStep = (step) => {
    updateTheActiveFlag(step);
  };

  const setHistory = (step) => {
    history.push(step);
  };

  function setActiveStepValueBasedOnPathname(pathname) {
    const pages = {
      [CERTIFICATIONS_LICENSES]: -1,
      [BASIC_INFO_PAGE]: 0,
      [EDU_SPEC_PAGE]: 1,
      [WORK_EXP_PAGE]: 2,
      [REFERENCES_PAGE]: 3,
      [SKILL_CHECKLIST_OVERVIEW_PAGE]: 4,
      [SKILL_CHECKLIST_DETAILS_PAGE]: 4,
    };

    const activeStepValue = pages[`${pathname}`];
    setActiveStepValue(activeStepValue);
  }

  useEffect(() => {
    setCompleted(onBoardingCompletedSteps);
    if (location.pathname !== "/profile/dashboard")
      return setActiveStepValueBasedOnPathname(location.pathname);
    updateReduxActiveFlagStep(activeStep);
    return setActiveStepValue(activeStep);
  }, [onBoardingCompletedSteps, onBoardingActiveSteps, location.pathname]);

  const handleStep = (step) => () => {
    if (step === undefined || step === null) return;
    const stepToPage = {
      0: BASIC_INFO_PAGE,
      1: EDU_SPEC_PAGE,
      2: WORK_EXP_PAGE,
      3: REFERENCES_PAGE,
      4: SKILL_CHECKLIST_OVERVIEW_PAGE,
      default: BASIC_INFO_PAGE,
    };

    updateReduxActiveFlagStep(step);
    setActiveStepValue(step);
    setHistory(stepToPage[`${step}`] || stepToPage.default);
  };
  function getStepIcon(step) {
    const stepIcons = [
      <BasicInfoIcon />,
      <SpecialtyEduIcon />,
      <WorkExpIcon />,
      <PersonAddRoundedIcon />,
      <SkillsIcon />,
    ];

    const icon = completed[`${step}`] ? <DoneIcon /> : stepIcons[`${step}`] || <BasicInfoIcon />;
    return icon;
  }

  const redirectToLatestUncompletedStep = () => {
    const stepPages = [
      BASIC_INFO_PAGE,
      EDU_SPEC_PAGE,
      WORK_EXP_PAGE,
      REFERENCES_PAGE,
      SKILL_CHECKLIST_OVERVIEW_PAGE,
    ];
    const latestUncompletedStep = stepPages.findIndex(
      (_, index) => !onBoardingCompletedSteps[`${index}`]
    );
    const latestUncompletedStepFound = latestUncompletedStep !== -1;
    const pageToRedirect = latestUncompletedStepFound
      ? stepPages[`${latestUncompletedStep}`]
      : BASIC_INFO_PAGE;
    updateReduxActiveFlagStep(latestUncompletedStepFound ? latestUncompletedStep : 0);
    setHistory(pageToRedirect);
  };

  const pickUpWhereYouLeftOff = () => {
    const stepPages = [
      BASIC_INFO_PAGE,
      EDU_SPEC_PAGE,
      WORK_EXP_PAGE,
      REFERENCES_PAGE,
      SKILL_CHECKLIST_OVERVIEW_PAGE,
    ];
    if (onBoardingCompletedSteps[`${lastVisitedPage}`]) return redirectToLatestUncompletedStep();
    updateReduxActiveFlagStep(lastVisitedPage);
    return setHistory(stepPages[`${lastVisitedPage}`]);
  };

  const redirectToProfile = () => {
    if (onBoardingCompletedSteps[`${lastVisitedPage}`]) return pickUpWhereYouLeftOff();
    return redirectToLatestUncompletedStep();
  };

  const getStepperClass = (index) => {
    if (activeStep === index && completed[`${index}`]) {
      return "onBStepperCompleted onBActiveCompleteStep";
    }
    if (activeStep === index) {
      return "onBStepperActiveStep";
    }
    if (completed[`${index}`]) {
      return "onBStepperCompleted";
    }
    return "onBStepperStep";
  };

  const renderStepper = () => {
    return (
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const timeToComplete = label.split(":");
          return (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[`${index}`]}
                className={getStepperClass(index)}
              >
                <span className="onBStepIcons">{getStepIcon(index)}</span>
                <span className="onBStepLabel">{timeToComplete[0]}</span>
                <span className="onBTimeToComplete">
                  {isMobile ? timeToComplete[1].substring(1) : timeToComplete[1]}
                </span>
              </StepButton>
            </Step>
          );
        })}
        <div className="MuiStepConnector-root MuiStepConnector-horizontal">
          <span className="MuiStepConnector-line MuiStepConnector-lineHorizontal" />
        </div>
        <span className={completedSteps === totalSteps ? "starIconWrapCompleted" : "starIconWrap"}>
          <span className="starIcon">
            <StarIcon />
          </span>
        </span>
      </Stepper>
    );
  };

  return (
    <div>
          <Row className={`pt-3 ${!isMobile ? "pb-3" : ""}`}>
        <Col sm={12} xs={12} md={12} lg={12}>
          <div className={clsx("onBStepper", enableDashboardAd && "onBStepperEnable")}>
            <p className={clsx("myProfileTextMob", enableDashboardAd && "myProfileTextMobEnable")} >
              <span className="myProfileNote myProfBannerTextComp">
                {" "}
                <span className={clsx("dashBannerTxtHead", enableDashboardAd && "dashBannerTxtHeadEnable")}>
                  {t("dashboard.banner.dashProfileBannerTxt1")}
                  <span>{username ? ` ${username}` : ""}</span>
                  !&nbsp;
                  <span className={clsx("displayBlockMob", enableDashboardAd && "displayBlockMobEnable")}>
                    {t("dashboard.banner.dashProfileBannerTxt2")}
                  </span>
                </span>
              </span>
              <span className={`${enableDashboardAdvertisement ? "dashBannerSubHeadEnable" : "dashBannerSubHead"}`}>
                {t("dashboard.banner.dashProfileBannerTxt3")}
              </span>
              <br />
              <span
                className="text-centerMob"
                role="button"
                tabIndex={0}
                onKeyDown={redirectToProfile}
                onClick={redirectToProfile}
              >
                <span className= {clsx(enableDashboardAd ? "dashProfileSubTxtAddReferenceEnable" : "dashProfileSubTxtAddReference", "dashProfileSubTxt")}>
                  {t("dashboard.banner.pickUpMessage")}
                  <ArrowForwardIosIcon fontSize="small" />
                </span>
              </span>
            </p>
            {enableDashboardAd && (
              <button
                type="button"
                tabIndex={0}
                className="completeMyProfile"
                onClick={redirectToProfile}
              >
                {t("dashboard.banner.completeMyProfile")}
              </button>
            )}
            {!isMobile ? renderStepper() : !enableDashboardAd && renderStepper() }
            <br />
            <br />
          </div>
         
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  username: state.auth.firstName,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  onBoardingActiveSteps: state.onBoarding.activeSteps,
  lastVisitedPage: state.onBoarding.lastVisitedPage,
});

const mapDispatchToProps = (dispatch) => ({
  updateTheActiveFlag: (activeFlag) => dispatch(updateActiveFlag(activeFlag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyProfileNav));
