import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import checkImg from "src/imagesV2/checkCircle.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

interface CertificationsLicensesUploadProps {
  uploaded: (File | null)[];
  setUploaded: (file: (File | null)[]) => void;
  twoSided: boolean;
  uploadedFilesIds: Number[] | null;
  setUploadedFileIdsCallBack: (id: Number[] | null) => void;
  setUploadedStatus: (rec: Record<Side, string | null>) => void;
  uploadedStatus: Record<Side, string | null>;
  isFileDownloading: boolean;
}
type Side = "front" | "back";
type UploadError = Record<Side, string | null>;

const sideToIndex = {
  front: 0,
  back: 1,
};

const CertificationsLicensesUpload: FC<CertificationsLicensesUploadProps> = ({
  uploaded,
  setUploaded,
  twoSided,
  uploadedFilesIds,
  setUploadedFileIdsCallBack,
  setUploadedStatus,
  uploadedStatus,
  isFileDownloading,
}): ReactElement => {
  const { t } = useTranslation();

  const [error, setError] = useState<UploadError>({ front: null, back: null });

  const handleDrop = (event, name: Side) => {
    event.preventDefault();

    const allowedExtensions = /(\.doc|\.docx|\.txt|\.pdf|\.jpg|\.jpeg|\.png)$/i;
    const file = event.dataTransfer.files[0];
    if (allowedExtensions.exec(file.name)) {
      if (file.size < 5000000) {
        const upload = [...uploaded];
        upload[sideToIndex[String(name)]] = file;
        setUploaded(upload);
        setError({ ...error, [name]: null });
      } else {
        setError({ ...error, [name]: t("certificationAndLicenses.error.size") });
      }
    } else {
      setError({ ...error, [name]: t("certificationAndLicenses.error.type") });
    }
  };

  const handleChange = (event, name: Side) => {
    const newFile = event?.target.files[0];
    const blob = newFile.slice(0, newFile.size, newFile.type);
    const fileNameArray = newFile.name.split(".");
    const file = new File([blob], `${fileNameArray[0]}_${name}.${fileNameArray[1]}`, {
      type: blob.type,
    });

    if (file && file.size < 5000000) {
      const upload = [...uploaded];
      upload[sideToIndex[String(name)]] = file;
      setUploaded(upload);
      setError({ ...error, [name]: null });
    } else {
      setError({ ...error, [name]: t("certificationAndLicenses.error.size") });
    }
  };

  const renderTextOrError = (side: Side) => {
    const errorMessage = error[String(side)];
    if (errorMessage) {
      return (
        <p>
          <b>{errorMessage}</b>
          {t("certificationAndLicenses.tryAgain")}
        </p>
      );
    }

    return twoSided ? (
      <span> {t(`certificationAndLicenses.${side}`)}</span>
    ) : (
      <span>
        <u>{t("certificationAndLicenses.chooseAFile")}</u>
        {t("certificationAndLicenses.orDragItHere")}
      </span>
    );
  };

  const renderEmptyState = (side: Side) => {
    const classNames = clsx(
      "uploadDropBox",
      "fileUploadError",
      error[String(side)] && "uploadError",
      twoSided && side === "back" && "uploadBack",
      twoSided && side === "front" && "uploadFront"
    );
    return (
      <label htmlFor={`upload${side}`} key={side}>
        <div
          className={classNames}
          onDrop={(e) => handleDrop(e, side)}
          onDragOver={(e) => e.preventDefault()}
        >
          <input
            id={`upload${side}`}
            onChange={(e) => handleChange(e, side)}
            hidden
            accept=".doc,.docx,.txt,.pdf,.jpg,.png"
            multiple
            type="file"
          />
          {renderTextOrError(side)}
        </div>
      </label>
    );
  };

  const handleDeleteFile = (side: Side) => {
    const updated = [...uploaded];
    updated[sideToIndex[String(side)]] = null;
    setUploaded(updated);

    if (uploadedFilesIds && uploadedFilesIds?.length > 0) {
      if (side === "front") {
        uploadedFilesIds.shift();
      } else {
        uploadedFilesIds.pop();
      }
    }

    setUploadedFileIdsCallBack(uploadedFilesIds);
    setUploadedStatus({ ...uploadedStatus, [side]: "" });
  };

  const replaceFileName = (fileName: string) => {
    return fileName?.toLowerCase().replace("_front", "").replace("_back", "");
  };

  const renderResult = (file: File, side: Side) => {
    const fileName = twoSided ? t(`certificationAndLicenses.${side}`) : replaceFileName(file.name);
    const fileSrc = file.type.includes("image") ? URL.createObjectURL(file) : checkImg;
    return (
      <div className="uploadResult" key={side}>
        <img alt="docImg" src={fileSrc} />
        <p>{fileName}</p>
        <div
          role="button"
          onClick={() => handleDeleteFile(side)}
          onKeyUp={() => handleDeleteFile(side)}
          tabIndex={0}
          className="uploadDelete"
        >
          {t("certificationAndLicenses.delete")}
        </div>
      </div>
    );
  };

  const renderUpload = (el: File | null, side: Side) => {
    if (el instanceof File) {
      return renderResult(el, side);
    }
    return renderEmptyState(side);
  };

  return (
    <>
      {isFileDownloading && (
        <div className="uploadWrapper">
          <div className={twoSided ? "uploadBoxWrapper" : ""}>
            {twoSided && (
              <div className="loader">
                <CircularProgress size="3rem" />
              </div>
            )}
            <div className="loader">
              <CircularProgress size="3rem" />
            </div>
          </div>
        </div>
      )}
      {!isFileDownloading && (
        <div className="uploadWrapper">
          <div className={twoSided ? "uploadBoxWrapper" : ""}>
            {uploaded.map((el, index) => renderUpload(el, index === 0 ? "front" : "back"))}
          </div>
          {uploaded.includes(null) && (
            <>
              <div className="fileRequired">{t("onBoarding.errorMessage.required")}</div>
              <span className="uploadWarning">{t("certificationAndLicenses.fileLimitText")}</span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CertificationsLicensesUpload;
