import { Component } from "react";
import { connect } from "react-redux";
import { detectJobCardClick } from "src/redux/jobDetails/action";
import Image from "react-bootstrap/Image";
import { isMobile } from "react-device-detect";
import { RouteComponentProps, withRouter } from "react-router-dom";
import JobCardIconLabelV2 from "src/components/common/MainContent/JobResults/JobCardIconLabelV2";
import { JOBS_DETAILS } from "src/router/routes";
import config from "src/config";
import MapIconJobDetail from "src/imagesV2/MapIconJobDetail.svg";
import imageResolution from "src/services/ImageResolution";
import jobsearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import { History } from "history";
import {
  jobDetailType,
  MyJobsFilters,
} from "src/components/common/MainContent/JobResults/common/index";
import EligibilityMeter, { EligibilityMeterRequirement } from "src/components/common/EligibilityMeter/EligibilityMeter";
import clsx from "clsx";
import getCandidateInformation, {OnboardingInfo} from "src/components/common/CandidateInformation/CandidateInformation";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import HotJobsIcon from "../Home/HotJobs/HotJobsIcon";

const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";

interface JobCardHeaderV2Props {
  job: jobDetailType;
  detectTheJobCardClick: (obj: any) => void;
  company: {
    id: string;
  };
  history: History;
  isRecommendedJobsOnResult?: boolean;
  campaignCode: string;
  sourceType: string;
  handleEmailCLick: (jobId: string) => void;
  similarOrViewed: string;
  recommended: boolean;
  viewMyJobs: boolean;
  myJobsFilter: MyJobsFilters;
  onboardingInfo: OnboardingInfo;
  atsBullhornCandidateId: string;
  bhId: string;
  isHotJobsCard?: boolean;
  jobsSource: RecommendedJobsSourceTypes;
  isQuickApplyAdvCard:boolean
}

interface JobCardHeaderV2State {
  imageResolutionDetails: string;
}

class JobCardHeaderV2 extends Component<
  JobCardHeaderV2Props & RouteComponentProps,
  JobCardHeaderV2State
> {
  constructor(props) {
    super(props);
    this.state = {
      imageResolutionDetails: "",
    };
  }

  componentDidMount() {
    const { job } = this.props;
    const imageResolutionDetails = imageResolution(
      job.imageUri,
      isMobile ? "Mobile" : "Desktop",
      "jobCard"
    );
    this.setState({ imageResolutionDetails });
  }

  componentDidUpdate(prevProps) {
    const { job } = this.props;
    if (prevProps.job !== job) {
      const imageResolutionDetails = imageResolution(
        job.imageUri,
        isMobile ? "Mobile" : "Desktop",
        "jobCard"
      );
      this.setImageRes(imageResolutionDetails);
    }
  }

  setImageRes = (imgResolution) => {
    this.setState({ imageResolutionDetails: imgResolution });
  };

  handleClick = (job): void => {
    const {
      isRecommendedJobsOnResult,
      campaignCode,
      company,
      sourceType,
      detectTheJobCardClick,
      history,
      myJobsFilter,
      onboardingInfo,
      atsBullhornCandidateId,
      bhId,
      jobsSource
    } = this.props;
    if (myJobsFilter === "assignments") {
      return;
    }

    window.scrollTo(0, 0);
    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
    const candidateInformation = getCandidateInformation(onboardingInfo)
    const obj = {
      isCardClicked: true,
      job,
      isRecommended: isRecommendedJobsOnResult ? true : null,
      campaignCode,
      companyId: company.id,
      size: config.JOB_COUNT,
      candidateId: atsBullhornCandidateId || bhId,
      candidateInformation,
    };
    detectTheJobCardClick(obj);
    const jobsSourceParam = (jobsSource === RecommendedJobsSourceTypes.NONE || jobsSource === undefined) ? "" : `&jobsSource=${jobsSource}`;

    const queryString = `jobId=${job.id}&companyId=${company.id
      }&campaignCode=${campaignCode}&isRecommended=${isRecommendedJobsOnResult ? true : null
      }&isApplied=${job.isApplied}&sourceType=${sourceType}${jobsSourceParam}`;
    jobsearchQueryBuilder.setOpenJobDetails(true);
    history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
  };

  render() {
    const {
      job,
      handleEmailCLick,
      similarOrViewed,
      recommended,
      isRecommendedJobsOnResult,
      viewMyJobs,
      campaignCode,
      sourceType,
      isHotJobsCard,
      jobsSource,
      isQuickApplyAdvCard
    } = this.props;
    const { imageResolutionDetails } = this.state;

    const showEligibilityMeter = enableEligibilityMeter && job?.isQuickApplied !== true && job?.isJobAppliedOrAssigned !== true;
    const eligibilityMeterRequirements: EligibilityMeterRequirement[] = [
      { isMet: job?.quickApplyEligibility?.qualifications?.ssnAndDob?.quickApply  === true },
      { isMet: job?.quickApplyEligibility?.qualifications?.workHistory?.quickApply === true },
      { isMet: job?.quickApplyEligibility?.qualifications?.reference?.quickApply === true },
      ...(job?.quickApplyEligibility?.qualifications?.credential?.credentials != null &&
          job?.quickApplyEligibility?.qualifications?.credential?.credentials?.length > 0 ? [{
        isMet: job?.quickApplyEligibility?.qualifications?.credential?.quickApply === true
      }] : []),
      { isMet: job?.quickApplyEligibility?.qualifications?.checkList?.quickApply === true }
    ];

    return (
    
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => this.handleClick(job)}
        onClick={() => this.handleClick(job)}
      >
        {!isHotJobsCard && !isQuickApplyAdvCard && <div className="jobCardBackImg">
          {job.imageUri ? (
            <Image
              src={`${config.JOB_CARD_IMAGE_URI_DOMAIN}${imageResolutionDetails}`}
              className="mbCardImage"
            />
          ) : (
            <Image
              src={`${config.JOB_CARD_IMAGE_URI_DOMAIN}${config.JOB_CARD_DEFAULT_IMAGE}`}
              className="mbCardImage"
            />
          )}

          <div className="jobCardOverlayImg" />
        </div>}
           
        {isHotJobsCard && <HotJobsIcon />}
        {isQuickApplyAdvCard && null}
        {!isHotJobsCard && !isQuickApplyAdvCard && (
          <JobCardIconLabelV2
            job={job}
            handleEmailClick={handleEmailCLick}
            similarOrViewed={similarOrViewed}
            recommended={recommended}
            isRecommendedJobsOnResult={isRecommendedJobsOnResult}
            viewMyJobs={viewMyJobs}
            campaignCode={campaignCode}
            sourceType={sourceType}
            jobsSource={jobsSource}
          />
        )}
        
        <div className={clsx("jobCardHeader",isQuickApplyAdvCard && "pb-0")}>
          <div className={clsx("hospitalNameV2",isQuickApplyAdvCard && "quickApplyHospitalName", !showEligibilityMeter && "noEligibilityMeter")}>
            <Image src={MapIconJobDetail} className="mapIconJobCard" />
            <span>{job.city}, {job.state}</span>

            <div className={clsx("jobFacilityName",isQuickApplyAdvCard && "quickApplyFacilityName")}>
              <span>{job.facilityName}</span>
            </div>
          </div>

          {!isHotJobsCard && showEligibilityMeter && (
           
            <div className={clsx( isQuickApplyAdvCard && "quickApplyAdvEligibilityContainer")}>
               <EligibilityMeter
              requirements={eligibilityMeterRequirements}
              showQuickApplyText
              eligibilityPercent ={job.quickApplyPercentage}
            />
              </div>
           
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewJobDetails: state.jobDetails.viewJobDetails,
  company: state.company.company,
  myJobsFilter: state.myJobs.filterSelected,
  onboardingInfo: state.onBoarding,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
});

const mapDispatchToProps = (dispatch) => ({
  detectTheJobCardClick: (obj) => dispatch(detectJobCardClick(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobCardHeaderV2));
