export const HOME = "/";
export const AUTH = "/xdl";
export const LOGIN = "/login";
export const AUTO_LOGIN = "/login/:jwt";
export const REGISTER = "/register";
export const MY_JOBS = "/jobs/my-jobs";
export const JOBS_RESULTS = "/jobs/results";
export const JOBS_DETAILS = "/jobs/details";
export const APPLY = "/apply";
export const APPLY_COMPLETE = "/apply-complete";
export const LOGIN_JWT = "/auth/relay/:jwt";
export const LOGIN_JWT_URL = "/auth/relay/";
export const PASSWORD_RECOVERY = "/password-recovery/:jwt";
export const PASSWORD_RESET_JWT = "/password-reset/:jwt";
export const REDIRECT_JWT = "/auth/redirect/:token";
export const REDIRECT_JWT_URL = "/auth/redirect/";
export const PASSWORD_UPDATE = "/password-update";
export const PAYROLL_TIMESHEET = "/profile/payroll/timesheets";
export const PAYROLL_PAYSTUBS = "/profile/payroll/paystubs";
export const PAYROLL_PAYMENT_DEDUCTION = "/profile/payroll/payments-deductions";
export const PAYROLL_PAYSTUBS_DETAILS = "/profile/payroll/paystubs-details";
export const LATEST_TASKS = "/profile/tasks";
export const DASHBOARD = "/profile/dashboard";
export const EMPLOYEE_STREAM_TASKS = "https://staging.ableteams.com/profile/#/cch/hq/profile";
export const EMPLOYEE_STREAM_BASIC_INFO =
  "https://staging.ableteams.com/profile/#/cch/hq/profile/basic-info";
export const ACCOUNT_SETTINGS = "/profile/account-settings";
export const BENEFITS = "/profile/need-help/benefits";
export const OPEN_ENROLLMENT = "/profile/need-help/benefits/open-enrollment";
export const TAX_SAVING_PLUS = "/profile/needHelp/TaxSavingPlus";
export const MY_PROFILE = "/profile/my-profile";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_CREATE = "/password-create/:jwt";
export const BASIC_INFO_PAGE = "/profile/my-profile/basic-info";
export const EDU_SPEC_PAGE = "/profile/my-profile/education-specialties";
export const WORK_EXP_PAGE = "/profile/my-profile/work-exp";
export const REFERENCES_PAGE = "/profile/my-profile/references";
export const SKILL_AND_MORE_PAGE = "/profile/skills-and-more";
export const SKILL_CHECKLIST_OVERVIEW_PAGE = "/profile/my-profile/skill-checklist-overview";
export const SKILL_CHECKLIST_DETAILS_PAGE = "/profile/my-profile/skill-checklist-details";
export const SKILL_CHECKLIST_DETAILS_PROFILE_PAGE = "/profile/my-profile/skill-checklist-details";
export const CERTIFICATIONS_LICENSES = "/profile/certifications-and-licenses";
export const LOGOUT = "/logout";
export const LEGACY_PAGE = "/profile/my-profile/legacy";
export const SAVED_SEARCHES_PAGE = "/saved-searches";
export const REFERENCES_SURVEY_FORM = "/reference-survey";
export const REFERENCES_SURVEY_THANK_YOU = "/reference-survey/thank-you";
export const REFERENCES_SURVEY_FORM_EXPIRE = "/reference-survey/expired";
export const EMPLOYERS_REFERENCE = "/employers/reference";
export const MY_TEAM_PAGE = "/profile/my-team";
export const REFERENCE_GENERIC_APPLY = "/reference-generic-apply";
export const VERIFY_EMAIL = "/expired/:jwt";
