import clsx from "clsx";
import { isMobile } from "react-device-detect";
import styles from "src/components/common/EligibilityMeter/EligibilityMeter.module.scss";
import QuickApply from "src/imagesV2/EligibilityMeter/quick-apply.svg";
import { setEligibilityPercentage } from "src/redux/jobDetails/action";
import { connect } from "react-redux";
import { useEffect } from "react";

export interface EligibilityMeterRequirement {
  isMet: boolean;
}

interface EligibilityMeterProps {
  requirements: EligibilityMeterRequirement[];
  showQuickApplyText?: boolean;
  setEligibilityPercent: (eligibilityPercent: number | null) => void;
  eligibilityPercent? :number
}

const EligibilityMeter = ({
  requirements,
  showQuickApplyText,
  setEligibilityPercent,
  eligibilityPercent
}: EligibilityMeterProps) => {
  let eligibilityColor = {
    innerCircleColor: "#FDE3E4",
    barColor: "D7282F",
    barCircleColor: "#9A1B20",
    textColor: "#216D35",
  }; 
  const eligibilityPercentage = eligibilityPercent??requirements.filter((requirement) => requirement.isMet).length / requirements.length * 100;

  useEffect(() => {
    setEligibilityPercent(eligibilityPercentage > 20 ? eligibilityPercentage : null);
  }, [eligibilityPercentage, setEligibilityPercent]);

  
  if (eligibilityPercentage >= 20 && eligibilityPercentage <= 49) {
    eligibilityColor = {
      innerCircleColor: "#FDE3E4",
      barColor: "#D7282F",
      barCircleColor: "#9A1B20",
      textColor: "#7D1F23",
    };
  } else if (eligibilityPercentage >= 50 && eligibilityPercentage <= 79) {
    eligibilityColor = {
      innerCircleColor: "#FBDBC3",
      barColor: "#F67B35",
      barCircleColor: "#BF5E26",
      textColor: "#885110",
    };
  } else if (eligibilityPercentage >= 80 && eligibilityPercentage <= 99) {
    eligibilityColor = {
      innerCircleColor: "#FBF0C3",
      barColor: "#F6AA35",
      barCircleColor: "#C78724",
      textColor: "#9E6B1D",
    };
  } else if (eligibilityPercentage === 100) {
    eligibilityColor = {
      innerCircleColor: "rgba(50, 154, 78, 0.1)",
      barColor: "#329A4E",
      barCircleColor: "#26743B",
      textColor: "#216D35",
    };
  }

  function calculateRotation(percentage: number): number {
    const minRotation = isMobile ? -170 : -175; // Rotation for 0%
    const maxRotation = isMobile ? -10 : -5; // Rotation for 100%

    const rotation = minRotation + (percentage / 100) * (maxRotation - minRotation);

    return rotation;
  }

  return (
    <>
      {eligibilityPercentage > 20 ? (
        <div className={clsx(!showQuickApplyText && styles.noQuickApplyText)}>
          <div className={styles.wrapper}>
            <div
              className={styles.outerCircle}
              style={{ backgroundColor: eligibilityColor.innerCircleColor }}
            >
              <div className={styles.barWrapper}>
                <div
                  className={styles.bar}
                  style={
                    {
                      "--bar-border-color": `${eligibilityColor.barColor}`,
                      "--bar-circle-color": `${eligibilityColor.barCircleColor}`,
                      "--rotate-bar": `rotate(${calculateRotation(eligibilityPercentage)}deg)`,
                    } as React.CSSProperties
                  }
                />
              </div>
              <span
                className={styles.text}
                style={
                  { "--text-color": `${eligibilityColor.textColor}` } as React.CSSProperties
                }
              >
                {eligibilityPercentage}%
              </span>
            </div>
          </div>

          {showQuickApplyText && (
            <div>
              <img src={QuickApply} alt="quick-apply-icon" />
              <span className={styles.quickApplyText}>QUICK APPLY</span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

EligibilityMeter.defaultProps = {
  showQuickApplyText: false,
  eligibilityPercent: null
};

const mapStateToProps = (state) => ({
  eligibilityPercentages: state.eligibilityPercentage,
});

const mapDispatchToProps = (dispatch) => ({
  setEligibilityPercent: (eligibilityPercentage) =>
    dispatch(setEligibilityPercentage(eligibilityPercentage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityMeter);
