import API from "../../services/Api";
import { GET_BH_CANDIDATE_EXTERNAL_ID } from "../../services/GraphQL/graph.query";
import { graphqlRequest } from "../../services/GraphQL/GraphQL";

export const GET_CANDIDATE_LOCATION = "GET_CANDIDATE_LOCATION";
export const candidateCurrentLocation = (payload) => ({ type: GET_CANDIDATE_LOCATION, payload });

export const setCandidateCurrentLocation = (data) => (dispatch) => {
  dispatch(candidateCurrentLocation(data));
};

export const FETCH_CANDIDATE_PROFILE_REQUEST = "FETCH_CANDIDATE_PROFILE_REQUEST";
export const fetchCandidateProfileRequest = (payload) => ({
  type: FETCH_CANDIDATE_PROFILE_REQUEST,
  payload,
});

export const FETCH_CANDIDATE_PROFILE_SUCCESS = "FETCH_CANDIDATE_PROFILE_SUCCESS";
export const fetchCandidateProfileSuccess = (profileStatus, isComplete) => ({
  type: FETCH_CANDIDATE_PROFILE_SUCCESS,
  profileStatus,
  isComplete,
});

export const FETCH_CANDIDATE_PROFILE_FAILURE = "FETCH_CANDIDATE_PROFILE_FAILURE";
export const fetchCandidateProfileFailure = (payload) => ({
  type: FETCH_CANDIDATE_PROFILE_FAILURE,
  payload,
});

export const FETCH_ACCESS_TOKEN_REQUEST = "FETCH_ACCESS_TOKEN_REQUEST";
export const fetchAccessTokenRequest = (payload) => ({ type: FETCH_ACCESS_TOKEN_REQUEST, payload });

export const FETCH_ACCESS_TOKEN_SUCCESS = "FETCH_ACCESS_TOKEN_SUCCESS";
export const fetchAccessTokenSuccess = (accessToken) => ({
  type: FETCH_ACCESS_TOKEN_SUCCESS,
  accessToken,
});

export const FETCH_ACCESS_TOKEN_FAILURE = "FETCH_ACCESS_TOKEN_FAILURE";
export const fetchAccessTokenFailure = (payload) => ({ type: FETCH_ACCESS_TOKEN_FAILURE, payload });

export const fetchCandidateProfile = (payload) => (dispatch) => {
  dispatch(fetchCandidateProfileRequest(payload));
  API.get(`users/init/${payload}`)
    .then((response) => {
      const profileStatus = response.data.response.application_status[0].status;
      const isComplete = response.data.response.application_status[0].complete;
      localStorage.setItem("applicantStatus", profileStatus);
      sessionStorage.setItem("applicantStatus", profileStatus);
      dispatch(fetchCandidateProfileSuccess(profileStatus, isComplete));
    })
    .catch((error) => {
      dispatch(fetchCandidateProfileFailure(error));
    });
};

export const fetchAccessToken = (payload) => (dispatch) => {
  dispatch(fetchAccessTokenRequest(payload));
  API.get(`/users/es/token/${payload}`)
    .then((response) => {
      if (response.data.statusCode === 200) {
        const { accessToken } = response.data.response;
        dispatch(fetchAccessTokenSuccess(accessToken));
      }
    })
    .catch((error) => {
      dispatch(fetchAccessTokenFailure(error));
    });
};

export const CURRENT_ASSIGNMENT_DETAILS = "CURRENT_ASSIGNMENT_DETAILS";
export const currentAssignmentDetails = (payload) => ({
  type: CURRENT_ASSIGNMENT_DETAILS,
  payload,
});

export const SET_COMPLETE_STATUS_FROM_APPLY_COMPLETE_PAGE =
  "SET_COMPLETE_STATUS_FROM_APPLY_COMPLETE_PAGE";
export const setCompleteStatus = (payload) => ({
  type: SET_COMPLETE_STATUS_FROM_APPLY_COMPLETE_PAGE,
  payload,
});

export const SET_APPLICANT_ID = "SET_APPLICANT_ID";
export const setApplicantId = (payload) => ({ type: SET_APPLICANT_ID, payload });

export const SET_ATS_BULLHORN_CANDIDATE_ID = "SET_ATS_BULLHORN_CANDIDATE_ID";
export const setAtsBullhornCandidateId = (payload) => ({
  type: SET_ATS_BULLHORN_CANDIDATE_ID,
  payload,
});

export const IS_INIT_API_LOADING = "IS_INIT_API_LOADING";
export const isInitApiLoading = (payload) => ({ type: "IS_INIT_API_LOADING", payload });

export const SET_BULLHORN_ID_FROM_BULLHORN = "SET_BULLHORN_ID_FROM_BULLHORN";
export const fetchBHId = (email, authToken) => async (dispatch) => {
  try {
    const { getBHCandidateExternalId } = await graphqlRequest(
      GET_BH_CANDIDATE_EXTERNAL_ID,
      { email },
      authToken
    );

    if (getBHCandidateExternalId?.code === 200) {
      dispatch({
        type: SET_BULLHORN_ID_FROM_BULLHORN,
        payload: getBHCandidateExternalId.bhCandidateId,
      });
    } else console.error(getBHCandidateExternalId?.developerMessage);
  } catch (error) {
    console.error(error);
  }
};
