import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  contractLengths,
  shiftLengths,
  shiftTypes,
  jobDetails,
} from "src/constants/filterConstants";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import config from "src/config";
import FilterSlider from "src/components/common/MainContent/Home/FilterSlider";
import FilterDate from "src/components/common/MainContent/Home/FilterDate";
import FilterChip from "src/components/common/MainContent/Home/FilterChip";
import FilterFacilities from "src/components/common/MainContent/Home/FilterFacilities";
import { isMobile } from "react-device-detect";

interface FilterMasterReduxState {
  jobResults: {
    aggregation: {
      minSalary: number;
      maxSalary: number;
      facilities: {facilityName: string}[];
    };
  };
}

interface FilterMasterFacilityReduxState {
  myJobs: {
    myJobs: {
      _embedded: {
        aggregations: {
          minSalary: number;
          maxSalary: number;
          facilities: { facilityName: string }[];
        };
      };
    };
    loading: boolean;
  };
}

const FilterMaster = ({jobsLoading} : {jobsLoading: boolean;}) => {
  const { t: translate } = useTranslation();
  const { search } = useLocation();
  const getStartDateFromQueryString = (
    queryValue: string | (string | null)[] | null
  ): string | null => {
    if (Array.isArray(queryValue)) {
      if (queryValue[0]) return queryValue[0];
      return null;
    }
    if (queryValue) return queryValue;
    return null;
  };

  const [showSkeleton, setShowSkeleton] = useState(jobsLoading);
  

  const queryStringValues = search ? queryString.parse(search) : {};

  useEffect(() => {
    const shiftTypeQueryParam = queryStringValues?.shiftTypes;
    const shiftLengthsQueryParam = queryStringValues?.shiftLengths;
    const durationsQueryParam = queryStringValues?.durations;
    const startDateQueryParam = queryStringValues?.startDate;
    const weeklyPayQueryParam = queryStringValues?.salaryMax;
    const jobDetailsQueryParam = queryStringValues?.jobDetails;
    const facilityNameQueryParam = queryStringValues?.facilityNames;

    const isAnyFilterSet = (
      shiftTypeQueryParam ||
      shiftLengthsQueryParam ||
      durationsQueryParam ||
      startDateQueryParam ||
      weeklyPayQueryParam ||
      jobDetailsQueryParam ||
      facilityNameQueryParam
    )

    setShowSkeleton(jobsLoading && !isAnyFilterSet)
  }, [jobsLoading])


  const getDefaultValues = (queryStringValue, typeArray) => {
    const queryStringValueArray = queryStringValue?.split(",");
    return typeArray.map((item) => {
      const newItem = item;
      newItem.isActive = !!queryStringValueArray?.includes(newItem.value);
      return newItem;
    });
  };

  const enableJobDetailsFilter = config.ENABLE_JOB_DETAILS_FILTER === "true";
  const enableFacilityFilter = config.ENABLE_FACILITY_FILTER === "true";

  const jobResultsFilters = "jobResults.filters.";

  const aggregationsObj = useSelector(
    (state: FilterMasterReduxState) => state.jobResults.aggregation
  );

  const aggregationObjectSearch = useSelector(
    (state: FilterMasterFacilityReduxState) => state.myJobs
  );

  const history = useHistory();
  const defStartDate = getStartDateFromQueryString(queryStringValues?.startDate);

  const [clearAll, setClearAll] = useState(true);
  const [clearShiftTypeFilter, setClearShiftTypeFilter] = useState(true);
  const [clearShiftLengthFilter, setClearShiftLengthFilter] = useState(true);
  const [clearContractLengthFilter, setClearContractLengthFilter] = useState(true);
  const [clearStartDateFilter, setClearStartDateFilter] = useState(true);
  const [clearWeeklyPayFilter, setClearWeeklyPayFilter] = useState(true);
  const [clearJobDetailFilter, setClearJobDetailFilter] = useState(true);
  const [shifts] = useState(getDefaultValues(queryStringValues?.shiftTypes, shiftTypes));
  const [shiftLength] = useState(getDefaultValues(queryStringValues?.shiftLengths, shiftLengths));
  const [clearAllFacilities, setClearAllFacilities] = useState(false);
  const [contractLength] = useState(
    getDefaultValues(queryStringValues?.durations, contractLengths)
  );
  const [facilityNames, setFacilityNames] = useState(aggregationsObj.facilities);
  const [jobDetail] = useState(getDefaultValues(queryStringValues?.jobDetails, jobDetails));
  const [startDate, setStartDate] = useState(defStartDate);
  const [weeklyPay, setWeeklyPay] = useState(queryStringValues?.salaryMax);
  const [maxSal, setMaxPay] = useState(aggregationsObj.maxSalary);

  useEffect(() => {
    const shiftTypeQueryParam = queryStringValues?.shiftTypes;
    const shiftLengthsQueryParam = queryStringValues?.shiftLengths;
    const durationsQueryParam = queryStringValues?.durations;
    const startDateQueryParam = queryStringValues?.startDate;
    const weeklyPayQueryParam = queryStringValues?.salaryMax;
    const jobDetailsQueryParam = queryStringValues?.jobDetails;
    const facilityNameQueryParam = queryStringValues?.facilityNames;
    setClearAll(
      !(
        shiftTypeQueryParam ||
        shiftLengthsQueryParam ||
        durationsQueryParam ||
        startDateQueryParam ||
        weeklyPayQueryParam ||
        jobDetailsQueryParam ||
        facilityNameQueryParam
      )
    );
    setClearShiftTypeFilter(!shiftTypeQueryParam);
    setClearShiftLengthFilter(!shiftLengthsQueryParam);
    setClearContractLengthFilter(!durationsQueryParam);
    setClearStartDateFilter(!startDateQueryParam);
    setClearWeeklyPayFilter(!weeklyPayQueryParam);
    setClearJobDetailFilter(!jobDetailsQueryParam);
    setWeeklyPay(weeklyPayQueryParam);
    setStartDate(defStartDate);
  });

  useEffect(() => {
    setMaxPay(aggregationsObj.maxSalary);
  }, [aggregationsObj]);

  const filterAndConcatValues = (criteriaObj) => {
    const filterCriteria = criteriaObj
      .filter((item) => item.isActive)
      ?.map((element) => element.value)
      .join(",");
    return filterCriteria;
  };

  const concatValues = (criteriaObj) => {
    return criteriaObj ? criteriaObj?.join(",") : "";
  };

  const handleSearchQueryBuilder = (filteredValues = "", specialKey = "", specialValue = "") => {
    jobSearchQueryBuilder.handleParamsChange({
      [specialKey]: specialValue,
      refreshPayFilter: filteredValues ? "false" : "true",
      offset: "1",
    });
  };

  const setFilterCriteriaObj = (type, criteriaObj) => {
    jobSearchQueryBuilder.setIsFilteredJobs(true);
    let filteredValues;

    switch (type) {
      case "shiftTypes":
        filteredValues = filterAndConcatValues(criteriaObj);
        handleSearchQueryBuilder(filteredValues, "shiftTypes", filteredValues);
        break;
      case "shiftLengths":
        filteredValues = filterAndConcatValues(criteriaObj);
        handleSearchQueryBuilder(filteredValues, "shiftLengths", filteredValues);
        break;
      case "contractLengths":
        filteredValues = filterAndConcatValues(criteriaObj);
        handleSearchQueryBuilder(filteredValues, "durations", filteredValues);
        break;
      case "facilityNames":
        filteredValues = concatValues(criteriaObj);
        handleSearchQueryBuilder(filteredValues, "facilityNames", filteredValues);
        break;
      case "startDate":
        if (
          criteriaObj &&
          moment(criteriaObj).isValid() &&
          moment(criteriaObj).isSameOrAfter(moment().toDate(), "year")
        ) {
          const formattedDate = criteriaObj ? moment(criteriaObj).format("YYYY-MM-DD") : null;
          jobSearchQueryBuilder.handleParamsChange({
            startDate: formattedDate || "",
            refreshPayFilter: formattedDate ? "false" : "true",
            offset: "1",
          });
        } else {
          jobSearchQueryBuilder.handleParamsChange({
            startDate: "",
            refreshPayFilter: "true",
            offset: "1",
          });
        }
        break;
      case "jobDetails":
        filteredValues = filterAndConcatValues(criteriaObj);
        jobSearchQueryBuilder.handleParamsChange({
          jobDetails: filteredValues || "",
          msp: (filteredValues.includes("exclusive") && "Y") || "",
          datePosted:
            (filteredValues.includes("new") &&
              moment.utc().subtract(1, "days").format("YYYY-MM-DD")) ||
            "",
          refreshPayFilter: filteredValues ? "false" : "true",
          offset: "1",
        });
        break;
      case "weeklyPay":
        jobSearchQueryBuilder.handleParamsChange({
          salaryMax: criteriaObj || "",
          refreshPayFilter: criteriaObj ? "false" : "true",
          offset: "1",
        });
        break;
      default:
        break;
    }
  };

  const checkActive = (obj) => obj.filter((item) => item.isActive === true)?.length > 0;

  const handleClear = () => {
    const isShiftTypeFilterActive = checkActive(shifts);
    const isShiftLengthFilterActive = checkActive(shiftLength);
    const isContractLengthFilterActive = checkActive(contractLength);
    const isJobDetailFilterActive = checkActive(jobDetail);
    const isFacilityNamesFilterActive = checkActive(facilityNames);

    setShowSkeleton(false);

    if (isShiftTypeFilterActive) {
      setClearShiftTypeFilter(false);
    } else {
      setClearShiftTypeFilter(true);
    }

    if (isShiftLengthFilterActive) {
      setClearShiftLengthFilter(false);
    } else {
      setClearShiftLengthFilter(true);
    }

    if (isContractLengthFilterActive) {
      setClearContractLengthFilter(false);
    } else {
      setClearContractLengthFilter(true);
    }

    if (isJobDetailFilterActive) {
      setClearJobDetailFilter(false);
    } else {
      setClearJobDetailFilter(true);
    }

    if (startDate) {
      setClearStartDateFilter(false);
    } else {
      setClearStartDateFilter(true);
    }

    if (weeklyPay) {
      setClearWeeklyPayFilter(false);
    } else {
      setClearWeeklyPayFilter(true);
    }

    setClearAll(false);

    if (
      !isShiftTypeFilterActive &&
      !isShiftLengthFilterActive &&
      !isContractLengthFilterActive &&
      !startDate &&
      !isJobDetailFilterActive &&
      !isFacilityNamesFilterActive &&
      !weeklyPay 
    ) {
      setClearAll(true);
      setClearShiftTypeFilter(true);
      setClearShiftLengthFilter(true);
      setClearContractLengthFilter(true);
      setClearStartDateFilter(true);
      setClearJobDetailFilter(true);
      setClearWeeklyPayFilter(true);
    }
  };

  const handleShiftChange = (shift) => {
    const copyShift = [...shifts];
    const updatedShift = copyShift.filter((item) => item.value === shift.value);
    if (updatedShift) {
      updatedShift[0].isActive = !updatedShift[0].isActive;
    }

    setFilterCriteriaObj("shiftTypes", copyShift);
    handleClear();
  };

  const handleFacilityChange = (selectedFacilities) => {
    const facilityNameArr = selectedFacilities.reduce((acc, facilityObj) => {
      acc.push(facilityObj.facilityName);
      return acc;
    }, []);
    setFilterCriteriaObj("facilityNames", facilityNameArr);
    setClearAllFacilities(false);
    setClearAll(false);
  };

  const handleJobDetailChange = (jobDetailFilter) => {
    const copyJobDetail = [...jobDetail];

    const updatedJobDetails = copyJobDetail.filter((item) => item.value === jobDetailFilter.value);
    if (updatedJobDetails) {
      updatedJobDetails[0].isActive = !updatedJobDetails[0].isActive;
    }

    setFilterCriteriaObj("jobDetails", copyJobDetail);
    handleClear();
  };

  const handleShiftLengthChange = (shiftLen) => {
    const copyShiftLength = [...shiftLength];
    const updatedShiftLength = copyShiftLength.filter((item) => item.value === shiftLen.value);
    if (updatedShiftLength) {
      updatedShiftLength[0].isActive = !updatedShiftLength[0].isActive;
    }
    setFilterCriteriaObj("shiftLengths", copyShiftLength);
    handleClear();
  };

  const handleContractLengthChange = (contractLen) => {
    const copyContractLength = [...contractLength];
    const updatedContractLength = copyContractLength.filter(
      (item) => item.value === contractLen.value
    );
    if (updatedContractLength) {
      updatedContractLength[0].isActive = !updatedContractLength[0].isActive;
    }
    setFilterCriteriaObj("contractLengths", copyContractLength);
    handleClear();
  };

  const handleStartDateChange = (startingDate) => {
    setFilterCriteriaObj("startDate", moment(startingDate).format("YYYY-MM-DD"));
    setStartDate(moment(startingDate).format("YYYY-MM-DD"));
    handleClear();
  };

  const handleSliderChange = (event, value) => {
    if (event.type === "mouseup" || event.type === "touchend") {
      setFilterCriteriaObj("weeklyPay", value);
      setWeeklyPay(value);
      handleClear();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
  }, []);

  const handleOnClickClearAll = () => {
    jobSearchQueryBuilder.setIsFilteredJobs(true);
    setClearAll(true);
    setClearShiftTypeFilter(true);
    setClearShiftLengthFilter(true);
    setClearContractLengthFilter(true);
    setClearStartDateFilter(true);
    setClearJobDetailFilter(true);
    setClearWeeklyPayFilter(true);
    jobSearchQueryBuilder.handleParamsChange({
      shiftTypes: "",
      shiftLengths: "",
      durations: "",
      startDate: "",
      sort: "relevant",
      msp: "",
      salaryMax: "",
      refreshPayFilter: "true",
      offset: "1",
      jobDetails: "",
      datePosted: "",
      facilityNames: "",
    });
    setStartDate("");
    setWeeklyPay("");
    setClearAllFacilities(true);
  };

  const handleOnClickClearShiftTypeFilter = () => {
    const resetShift = [...shifts];
    const copyShift = resetShift.map((shift) => ({ ...shift, isActive: false }));
    setFilterCriteriaObj("shiftTypes", copyShift);
  };

  const handleOnClickClearShiftLengthFilter = () => {
    const resetShiftLength = [...shiftLength];
    const updatedShiftLength = resetShiftLength.map((shiftLen) => ({
      ...shiftLen,
      isActive: false,
    }));
    setFilterCriteriaObj("shiftLengths", updatedShiftLength);
  };

  const handleOnClickClearContractLengthFilter = () => {
    const resetContractLength = [...contractLength];
    const copyContractLength = resetContractLength.map((contractLen) => ({
      ...contractLen,
      isActive: false,
    }));
    setFilterCriteriaObj("contractLengths", copyContractLength);
  };

  const handleOnClickClearStartDateFilter = () => {
    setFilterCriteriaObj("startDate", null);
    setStartDate("");
  };

  const handleOnClickClearJobDetailFilter = () => {
    const resetJobDetail = [...jobDetail];
    const copyJobDetail = resetJobDetail.map((jobDetailFilter) => ({
      ...jobDetailFilter,
      isActive: false,
    }));
    setFilterCriteriaObj("jobDetails", copyJobDetail);
  };

  const handleOnClickClearWeeklyPayFilter = () => {
    setFilterCriteriaObj("weeklyPay", "");
    setWeeklyPay("");
  };

  const handleFacilityClearAll = () => {
    setClearAll(false);
    setFilterCriteriaObj("facilityNames", "");
  };

  useEffect(() => {
      const facilitiesArray = aggregationObjectSearch?.myJobs?._embedded?.aggregations?.facilities
        ?.map((facilityObj) => ({
          ...facilityObj,
          isActive: false,
        }));
      setFacilityNames(facilitiesArray);
  }, [aggregationObjectSearch]);

  return (
    <div className="filterMaster">
      <div className="row d-flex">
        <div className={isMobile ? "col-2" : "col-6"}>
          <div className="filterTxt">{translate(`${jobResultsFilters}filters`)}</div>
        </div>
        <div className={isMobile ? "col-3" : "col-6"}>
          {!clearAll && (
            <div
              className="filterClearAll"
              onClick={handleOnClickClearAll}
              role="button"
              onKeyDown={handleOnClickClearAll}
              tabIndex={0}
            >
              {translate(`${jobResultsFilters}clearAll`)}{" "}
            </div>
          )}
        </div>
      </div>
      <div>
        <Divider variant="middle" className="filterDivider" />
      </div>

      <div className="filterSubHeads">{translate(`${jobResultsFilters}shiftType`)}</div>
      {!clearShiftTypeFilter && (
        <div
          className="filterClear"
          onClick={handleOnClickClearShiftTypeFilter}
          role="button"
          onKeyDown={handleOnClickClearShiftTypeFilter}
          tabIndex={0}
        >
          {" "}
          {translate(`${jobResultsFilters}clear`)}{" "}
        </div>
      )}
      <div>
        <FilterChip chips={shifts} handleChange={handleShiftChange} />
      </div>

      <div>
        <Divider variant="middle" className="filterDivider" />
      </div>
      <div className="filterSubHeads">{translate(`${jobResultsFilters}shiftLength`)}</div>
      {!clearShiftLengthFilter && (
        <div
          className="filterClear"
          onClick={handleOnClickClearShiftLengthFilter}
          role="button"
          onKeyDown={handleOnClickClearShiftLengthFilter}
          tabIndex={0}
        >
          {" "}
          {translate(`${jobResultsFilters}clear`)}{" "}
        </div>
      )}
      <div>
        <FilterChip chips={shiftLength} handleChange={handleShiftLengthChange} />
      </div>
      <div>
        <Divider variant="middle" className="filterDivider" />
      </div>
      <div className="filterSubHeads">{translate(`${jobResultsFilters}contractLength`)}</div>
      {!clearContractLengthFilter && (
        <div
          className="filterClear"
          onClick={handleOnClickClearContractLengthFilter}
          role="button"
          onKeyDown={handleOnClickClearContractLengthFilter}
          tabIndex={0}
        >
          {" "}
          {translate(`${jobResultsFilters}clear`)}{" "}
        </div>
      )}
      <div>
        <FilterChip chips={contractLength} handleChange={handleContractLengthChange} />
      </div>
      <div>
        <Divider variant="middle" className="filterDivider" />
      </div>
      {!enableJobDetailsFilter ? (
        <>
          <div className="filterSubHeads">{translate(`${jobResultsFilters}startDate`)}</div>
          {!clearStartDateFilter && (
            <div
              className="filterClear"
              onClick={handleOnClickClearStartDateFilter}
              role="button"
              onKeyDown={handleOnClickClearStartDateFilter}
              tabIndex={0}
            >
              {" "}
              {translate(`${jobResultsFilters}clear`)}{" "}
            </div>
          )}
          <div>
            <FilterDate startDate={startDate} handleStartDateChange={handleStartDateChange} />
          </div>
          <div>
            <Divider variant="middle" className="filterDivider" />
          </div>
        </>
      ) : null}

      {enableJobDetailsFilter ? (
        <>
          <div className="filterSubHeads">{translate(`${jobResultsFilters}jobDetails`)}</div>
          {!clearJobDetailFilter && (
            <div
              className="filterClear"
              onClick={handleOnClickClearJobDetailFilter}
              role="button"
              onKeyDown={handleOnClickClearJobDetailFilter}
              tabIndex={0}
            >
              {" "}
              {translate(`${jobResultsFilters}clear`)}{" "}
            </div>
          )}
          <div>
            <FilterChip chips={jobDetail} handleChange={handleJobDetailChange} />
          </div>
          <div>
            <Divider variant="middle" className="filterDivider" />
          </div>
        </>
      ) : null}

      <div className="filterSubHeads">{translate(`${jobResultsFilters}weeklyPay`)}</div>
      {!clearWeeklyPayFilter &&
        typeof weeklyPay === "string" &&
        parseInt(weeklyPay, 10) !== aggregationsObj.minSalary && (
          <div
            className="filterClear"
            onClick={handleOnClickClearWeeklyPayFilter}
            role="button"
            onKeyDown={handleOnClickClearWeeklyPayFilter}
            tabIndex={0}
          >
            {" "}
            {translate(`${jobResultsFilters}clear`)}{" "}
          </div>
        )}
      <div>
        <FilterSlider
          weeklyPay={weeklyPay}
          minVal={aggregationsObj.minSalary || 0}
          maxVal={maxSal || 0}
          handleSliderChange={handleSliderChange}
        />
      </div>
      {enableFacilityFilter && (
        <div>
          <Divider variant="middle" className="filterDivider" />
          <div>
            <FilterFacilities
              facilities={facilityNames?.map((facilityObj) => ({
                ...facilityObj,
                isActive: false,
                displayFacilityName: facilityObj.facilityName.trim()
              }))}
              showSkeleton={showSkeleton}
              handleChange={handleFacilityChange}
              setFacilitiesToEmpty={clearAllFacilities}
              handleFacilityClearAll={handleFacilityClearAll}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterMaster;
