import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CardActions from "@mui/material/CardActions";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import referenceIcon from "./images/referenceIcon.svg";
import "./scss/employers.scss";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Axios from "axios";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { uuid } from "uuidv4";
import jsonwebtoken from "jsonwebtoken";
import { useTranslation } from "react-i18next";
import { async } from "validate.js";
import FormHelperText from "@mui/material/FormHelperText";
import orderBy from "lodash/orderBy";
import { useSelector } from "react-redux";
import { SET_REFERENCES } from "src/services/GraphQL/graph.query";
import { APIMethods, ReferencesStates } from "./constants/constants";
import config from "src/config";
import {
  getUserByExternalId,
  getAllReferencesQuery,
  setReferenceQuery,
  deleteReferenceQuery,
  syncProfileDataFromAbleQuery,
  syncProfileDataFromBullhornQuery,
  GET_ALL_WORK_EXPERIENCE,
} from "./GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import combinedReferences from "src/services/CombinedReferences";
import surveyConstraint from "src/employers/reference/ReferencesSurveyConstraint";
import validationsService from "src/services/Validations";
import ReferencesTable from "src/employers/reference/ReferenceTable";

const enableWorkHistory = config.ENABLE_WORK_HISTORY === "true";

const ReferenceForm = (props) => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const { EntityID, UserID } = queryStringValues;
  const requiredErrMsg = "* Required";
  const [userData, setUserData] = useState({});
  const [candidateWorkExp, setCandidateWorkExp] = useState([]);
  const [candidateReferences, setCandidateReferences] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [facilityObjectList, setFacilityObjectList] = useState([]);
  const [facilityName, setFacilityName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [profession, setProfession] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [facilityLocation, setFacilityLocation] = useState(null);
  const [employed, setEmployed] = useState(null);
  const [isReferenceFormOpen, setIsReferenceFormOpen] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isCancelBtnDisabled, setIsCancelBtnDisabled] = useState(true);
  const [candidateReferencesMeta, setCandidateReferencesMeta] = useState({});
  const [mode, setMode] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [contactConsent, setContactConsent] = useState({
    value: "no",
    errors: false,
    errorText: "",
  });
  const [facilityNameDropDown, setFacilityNameDropDown] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const referencesIsDeleted = config.REFERENCES_IS_DELETED === "true";
  const workExpIsDeleted = config.WORK_EXPERIENCES_IS_DELETED === "true";
  const referencesFields = [
    "firstName",
    "lastName",
    "title",
    "facilityNameDropDown",
    "email",
    "phone",
  ];
  const [firstName, setFirstName] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [lastName, setLastName] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [title, setTitle] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [email, setEmail] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [phone, setPhone] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [emailPhoneErrors, setEmailPhoneErrors] = useState(false);
  const titleList = [
    "Charge Nurse",
    "Director",
    "Manager",
    "Nurse Educator",
    "Supervisor",
    "Team Lead",
  ];
  const [attendanceStandards, setAttendanceStandards] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [professionalStandards, setProfessionalStandards] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [clinicalStandards, setClinicalStandards] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [declinationReason, setDeclinationReason] = useState("");
  const [declineQuestionnaire, setDeclineQuestionnaire] = useState(false);
  const [additionalComments, setAdditionalComments] = useState("");
  const [surveyQuestionsError, setSurveyQuestionsError] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [referenceStatus, setReferenceStatus] = useState("");
  const [isDisabledSync, setIsDisabledSync] = useState(false);
  const [addReferenceToDb, { data: addRefResponse, loading: addRefLoading, error: addRefError }] =
    useMutation(setReferenceQuery);

  const [
    deleteReferenceFromDb,
    { data: delRefResponse, loading: delRefLoading, error: delRefError },
  ] = useMutation(deleteReferenceQuery);

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList = "";
  }, []);

  useEffect(async () => {
    try {
      document.getElementsByTagName("body")[0].classList = "";
      const query = {};
      query.externalId = EntityID?.toString();
      query.type = "BH";
      const userResponse = await request(getUserByExternalId, query);
      setUserData(userResponse?.userByExternalId);
    } catch (error) {
      console.error("Error while fetching user details by externalId:", error);
    }
  }, [EntityID]);
  const company = useSelector((state) => state.company.company);
  useEffect(() => {
    if (userData?.email && userData?.candidates[0].companyId) {
      getAllReferences();
      getWorkExpDetailsForHCP();
    }
  }, [userData]);

  useEffect(() => {
    getWorkExpDetailsForHCP();
  }, [isDisabledSync]);

  useEffect(() => {
    generateFacilityList();
  }, [candidateWorkExp]);

  useEffect(() => {
    const isError = isSurveyQuestionsValidated();
    setSurveyQuestionsError(isError);
  }, [professionalStandards, clinicalStandards, attendanceStandards]);

  const getWorkExpDetailsForHCP = async () => {
    try {
      if (enableWorkHistory) {
        if (userData?.email) {
          const query = {};
          let workExperience = [];
          query.email = userData.email;
          query.isDeleted = workExpIsDeleted;
          const workExperienceResponse = await request(GET_ALL_WORK_EXPERIENCE, query);
          const workHistoryContent = workExperienceResponse?.getAllWorkExperience?.content;
          if (workHistoryContent && workHistoryContent.length > 0) {
            workExperience = workHistoryContent[0]?.candidateWorkExperience?.workExperience || [];
            if (workExperience && workExperience.length > 0) {
              setCandidateWorkExp(
                orderBy(workExperience, (experience) => moment(experience.startDate), ["desc"])
              );
            }
          }
        }
      } else {
        const res = await Axios(
          `${config.API_BASE_HOST}/ui/v1/onboardings/workExperience/search?email=${userData?.email}&companyId=${userData?.candidates[0].companyId}`
        );
        const content = res.data?.content;
        const todaysDate = moment(moment(), "YYYY-MM-DD");
        if (content?.workExperience && content?.workExperience.length > 0) {
          setCandidateWorkExp(
            orderBy(content.workExperience, (experience) => moment(experience.startDate), ["desc"])
          );
        }
      }
    } catch (error) {
      console.error("Error while fetching work exp for HCP:", error);
    }
  };
  const handleChange = (e, fieldType) => {
    setOpenModal(true);
    const fieldValue = e.target.value && e.target.value.trim() !== "" ? e.target.value : "";
    const validators = validationsService.validateSingleField(
      fieldValue,
      surveyConstraint.referencesFormConstraints[fieldType]
    );
    let obj = {};
    switch (fieldType) {
      case "firstName":
        obj = updateObj(firstName, fieldValue, validators);
        setFirstName((firstName) => ({
          ...firstName,
          ...obj,
        }));
        break;
      case "lastName":
        obj = updateObj(lastName, fieldValue, validators);
        setLastName((lastName) => ({
          ...lastName,
          ...obj,
        }));
        break;
      case "email":
        obj = updateObj(email, fieldValue, validators);
        obj.notificationSent = false;
        setEmail((email) => ({
          ...email,
          ...obj,
        }));
        setEmailPhoneErrors(false);
        break;
      case "phone":
        obj = updateObj(phone, fieldValue, validators);
        obj.notificationSent = false;
        setPhone((phone) => ({
          ...phone,
          ...obj,
        }));
        setEmailPhoneErrors(false);
        break;
    }
    setIsSaveBtnDisabled(verifySubmitBtnStatus);
    setIsCancelBtnDisabled(false);
  };
  const getAllReferences = async () => {
    try {
      const query = {};
      query.email = userData?.email;
      query.isDeleted = referencesIsDeleted;
      const referencesResponse = await request(getAllReferencesQuery, query);
      const references = combinedReferences(referencesResponse);
      let referencesMeta = {};
      for (let i = 0; i < referencesResponse.getAllReferences.content.length; i++) {
        if (referencesResponse.getAllReferences.content[i].companyId == company.id) {
          referencesMeta =
            referencesResponse.getAllReferences.content[i].candidateReference?.meta
              ?.completedSections?.references;
        }
      }

      setCandidateReferences(orderBy(references, (o) => moment(o.dateCreated), ["desc"]));
      setCandidateReferencesMeta(referencesMeta);
    } catch (error) {
      console.error("Error while fetching user details by externalId:", error);
    }
  };

  const verifySubmitBtnStatus = async () => {
    const surveyObj = {
      firstName: firstName.value,
      lastName: lastName.value,
      title: title.value,
      phoneNumber: phone.value,
      emailAddress: email.value,
      professionalStandards: professionalStandards.value,
      attendanceStandards: attendanceStandards.value,
      clinicalStandards: clinicalStandards.value,
    };
    const validators = validationsService.validateSetOfFields(
      surveyObj,
      surveyConstraint.referencesSurveyConstraint
    );
    if (validators || !(email.value || phone.value)) {
      setEnableSubmit(false);
    } else {
      setEnableSubmit(true);
    }
  };

  const radioBtnHandleChange = (e, fieldType) => {
    const fieldValue = e.target.value;
    const validators = validationsService.validateSingleField(
      fieldValue,
      surveyConstraint.referencesSurveyConstraint[fieldType]
    );
    let obj = {};
    switch (fieldType) {
      case "attendanceStandards":
        const attendanceStandardsValue = attendanceStandards.value;
        obj = updateObj(attendanceStandards, fieldValue, validators);
        setAttendanceStandards((attendanceStandards) => ({
          ...attendanceStandards,
          ...obj,
        }));
        break;
      case "professionalStandards":
        const professionalStandardsValue = professionalStandards.value;
        obj = updateObj(professionalStandards, fieldValue, validators);
        setProfessionalStandards((professionalStandards) => ({
          ...professionalStandards,
          ...obj,
        }));
        break;
      case "clinicalStandards":
        const clinicalStandardsValue = clinicalStandards.value;
        obj = updateObj(clinicalStandards, fieldValue, validators);
        setClinicalStandards((clinicalStandards) => ({
          ...clinicalStandards,
          ...obj,
        }));
        break;
    }
    verifySubmitBtnStatus();
  };

  const radioBtnHandleClick = (e, fieldType) => {
    const fieldValue = e?.target?._wrapperState?.initialValue;
    switch (fieldType) {
      case "attendanceStandards":
        const attendanceStandardsValue = attendanceStandards.value;
        if (attendanceStandardsValue === fieldValue) {
          setAttendanceStandards({
            value: "",
            errors: false,
            errorText: "",
          });
        }
        break;
      case "professionalStandards":
        const professionalStandardsValue = professionalStandards.value;
        if (professionalStandardsValue === fieldValue) {
          setProfessionalStandards({
            value: "",
            errors: false,
            errorText: "",
          });
        }
        break;
      case "clinicalStandards":
        const clinicalStandardsValue = clinicalStandards.value;
        if (clinicalStandardsValue === fieldValue) {
          setClinicalStandards({
            value: "",
            errors: false,
            errorText: "",
          });
        }
        break;
    }
  };

  const generateFacilityList = () => {
    const facilityNameList = [];
    const facilityObjectList = [];
    candidateWorkExp.forEach((workExp) => {
      const facilityObject = {
        facilityName: workExp.facilityName ? workExp.facilityName : null,
        startDate: workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : null,
        endDate: workExp.endDate ? moment.utc(workExp.endDate).format("MM/DD/YYYY") : null,
        facilityDropdownVal:
          `${workExp.facilityName ? workExp.facilityName : "NA"}` +
          " - " +
          `${workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : "NA"}` +
          " - " +
          `${workExp.endDate ? moment.utc(workExp.endDate).format("MM/DD/YYYY") : "Present"}`,
        profession: workExp.profession,
        specialty: workExp.specialty,
        facilityCityState: workExp.city,
        employed: workExp.endDate ? "No" : "Yes",
      };
      facilityObjectList.push(facilityObject);
      facilityNameList.push(
        `${workExp.facilityName ? workExp.facilityName : "NA"}` +
          " - " +
          `${workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : "NA"}` +
          " - " +
          `${workExp.endDate ? moment.utc(workExp.endDate).format("MM/DD/YYYY") : "Present"}`
      );
    });
    setFacilityList(facilityNameList);
    setFacilityObjectList(facilityObjectList);
  };
  const handleMultipleSelect = (event, type) => {
    const fieldValue =
      event.target.value && event.target.value.trim() !== "" ? event.target.value : "";
    let obj = {};
    if (type === "facilityName") {
      obj = updateObj(facilityNameDropDown, fieldValue, false);
      setFacilityNameDropDown((facilityNameDropDown) => ({
        ...facilityNameDropDown,
        ...obj,
      }));
      let facilityObj;
      facilityObjectList.forEach((facility) => {
        if (facility.facilityDropdownVal === event.target.value) {
          facilityObj = facility;
        }
      });
      setFacilityName(facilityObj.facilityName);
      setCompanyId(facilityObj.companyId);
      setStartDate(facilityObj.startDate);
      setEndDate(facilityObj.endDate);
      setProfession(facilityObj.profession);
      setSpecialty(facilityObj.specialty);
      setFacilityLocation(facilityObj.facilityCityState);
      setEmployed(facilityObj.employed);
    }
    if (type === "title") {
      obj = updateObj(title, fieldValue, false);
      setTitle((title) => ({
        ...title,
        ...obj,
      }));
    }
  };

  const updateObj = (prevObj, fieldValue, validators) => {
    prevObj.value = fieldValue;
    prevObj.errors = !!(validators && fieldValue.trim() !== "");
    prevObj.errorText = validators && fieldValue.trim() !== "" ? validators[0] : null;
    return prevObj;
  };

  const getFieldData = (field) => {
    if (field === "firstName") {
      return firstName.value && firstName.value.trim() !== "" ? firstName.value : null;
    }
    if (field === "lastName") {
      return lastName.value && lastName.value.trim() !== "" ? lastName.value : null;
    }
    if (field === "title") {
      return title.value && title.value.trim() !== "" ? title.value : null;
    }
    if (field === "facilityNameDropDown") {
      return facilityNameDropDown.value && facilityNameDropDown.value.trim() !== ""
        ? facilityNameDropDown.value
        : null;
    }
    if (field === "email") {
      return email.value && email.value.trim() !== "" ? email.value : "";
    }
    if (field === "phone") {
      return phone.value && phone.value.trim() !== "" ? phone.value : "";
    }
  };

  const handleSaveReferences = async (reqObj) => {
    try {
      await addReferenceToDb({ variables: { referencesInput: reqObj } });
      getAllReferences();
    } catch (error) {
      console.error("Error while saving references:", error);
    }
  };

  const getStatus = () => {
    if (declinationReason !== null && declinationReason !== "") return ReferencesStates.DECLINED;

    if (
      professionalStandards.value !== "" ||
      clinicalStandards.value !== "" ||
      attendanceStandards.value !== ""
    )
      return ReferencesStates.COMPLETE;
    return ReferencesStates.PENDING;
  };

  const isSurveyQuestionsValidated = () => {
    if (
      professionalStandards.value !== "" ||
      clinicalStandards.value !== "" ||
      attendanceStandards.value !== ""
    ) {
      if (
        (professionalStandards.value !== "" &&
          clinicalStandards.value !== "" &&
          attendanceStandards.value !== "") ||
        (professionalStandards.value === "" &&
          clinicalStandards.value === "" &&
          attendanceStandards.value === "")
      ) {
        return false;
      }
      return true;
    }
    return false;
  };
  const validationOnSaveAndEdit = () => {
    if (isSurveyQuestionsValidated()) {
      setSurveyQuestionsError(true);
    }
    if (firstName.value === "") {
      setFirstName({
        value: "",
        errors: true,
        errorText: "* Required",
      });
    }
    if (lastName.value === "") {
      setLastName({
        value: "",
        errors: true,
        errorText: "* Required",
      });
    }
    if (title.value === "") {
      setTitle({
        value: "",
        errors: true,
        errorText: "* Required",
      });
    }
    if (
      (email.value === "" && phone.value === "") ||
      (email.value === "" && phone.value === null) ||
      (email.value === null && phone.value === "") ||
      (email.value === null && phone.value === null)
    ) {
      setEmailPhoneErrors(true);
    }
    if (facilityNameDropDown.value === "") {
      setFacilityNameDropDown({
        value: "",
        errors: true,
        errorText: "* Required",
      });
    }
    if (contactConsent.value === "no" || contactConsent.value === "") {
      setContactConsent({
        value: "no",
        errors: true,
        errorText: "* Required",
      });
    }
  };
  const saveReferences = () => {
    if (
      isSurveyQuestionsValidated() ||
      firstName.value === "" ||
      lastName.value === "" ||
      title.value === "" ||
      (email.value === "" && phone.value === "") ||
      facilityNameDropDown.value === "" ||
      contactConsent.value === "no" ||
      contactConsent.value === "" ||
      firstName.errors ||
      lastName.errors ||
      email.errors ||
      phone.errors ||
      (declineQuestionnaire && declinationReason === "")
    ) {
      validationOnSaveAndEdit();
    } else {
      setSurveyQuestionsError(false);
      const status = getStatus();
      const record = {};
      referencesFields.forEach((field) => {
        record[field] = getFieldData(field);
      });
      record.facilityName = facilityName;
      record.startDate = startDate;
      record.endDate = endDate;
      record.status = status;
      record.id = uuid();
      record.notificationSent = status !== ReferencesStates.PENDING;
      record.profession = profession;
      record.specialty = specialty;
      record.facilityCityState = facilityLocation;
      record.employed = employed;
      record.referenceId = null;
      record.atsBullhornCandidateId = EntityID;
      (record.dateCompleted = status === ReferencesStates.COMPLETE ? moment.utc().format() : null),
        (record.dateCreated = moment.utc().format()),
        (record.dateLastUpdated = moment.utc().format()),
        (record.dateNotified = moment.utc().format());
      (record.declinationReason = declinationReason),
        (record.responseOne = attendanceStandards.value),
        (record.responseTwo = professionalStandards.value),
        (record.responseThree = clinicalStandards.value),
        (record.comments = additionalComments),
        (record.contactConsent = contactConsent.value);
      const referencesArr = candidateReferences || [];
      const { reqObj } = normalizeReferenceJson([record]);
      referencesArr.unshift(reqObj?.candidateReference?.references[0]);
      setCandidateReferences(orderBy(referencesArr, (o) => moment(o.dateCreated), ["desc"]));
      handleSaveReferences(reqObj);
      setOpenModal(false);
      resetStates();
    }
  };

  const cancelUpdate = () => {
    resetStates();
    setOpenModal(false);
  };

  const checkSaveBtnStatus = () => {
    const referenceRecord = {};
    referencesFields.forEach((field) => {
      referenceRecord[field] = getFieldData(field);
    });
    const validators = validationsService.validateSetOfFields(
      referenceRecord,
      surveyConstraint.referencesFormConstraints
    );
    if (
      (email.value === "" && phone.value === "") ||
      (email.value === "" && phone.value === null) ||
      (email.value === null && phone.value === "") ||
      (email.value === null && phone.value === null)
    ) {
      return true;
    }

    if (validators) {
      return true;
    }
    return false;
  };

  const resetStates = () => {
    setIsEdit(false);
    setFirstName({
      value: "",
      errors: false,
      errorText: "",
    });
    setLastName({
      value: "",
      errors: false,
      errorText: "",
    });
    setTitle({
      value: "",
      errors: false,
      errorText: "",
    });
    setFacilityNameDropDown({
      value: "",
      errors: false,
      errorText: "",
    });
    setEmail({
      value: "",
      errors: false,
      errorText: "",
    });
    setPhone({ value: "", errors: false, errorText: "" });
    setEmailPhoneErrors(false);
    setAttendanceStandards({
      value: "",
      errors: false,
      errorText: "",
    });
    setProfessionalStandards({
      value: "",
      errors: false,
      errorText: "",
    });
    setClinicalStandards({
      value: "",
      errors: false,
      errorText: "",
    });
    setContactConsent({
      value: "no",
      errors: false,
      errorText: "",
    });
    setDeclinationReason("");
    setDeclineQuestionnaire(false);
    setAdditionalComments("");
    setIsCancelBtnDisabled(true);
    setIsSaveBtnDisabled(checkSaveBtnStatus);
    setMode(null);
    setEditIndex(null);
    setIsReferenceFormOpen(false);
    setReferenceStatus("");
  };

  const validatePage = (reqObj) => {
    reqObj.references.forEach((obj) => {
      obj.facilityNameDropDown
        ? (obj.facilityNameDropDown = obj.facilityNameDropDown)
        : (obj.facilityNameDropDown = obj.facilityName);
    });
    const validators = validationsService.validateSetOfFields(
      reqObj,
      surveyConstraint.referencesPageConstraints
    );
    let canBeSavedPartially;
    if (validators && validators.references && !validators.references.includes("* Required")) {
      canBeSavedPartially = false;
    } else canBeSavedPartially = true;
    return { validators, canBeSavedPartially };
  };

  const normalizeReferenceJson = (currentReference) => {
    const pageValidationObj = {
      references: candidateReferences,
    };
    const canBeSavedPartially = true;
    let reqObj;
    currentReference.forEach((reference) => {
      delete reference.facilityNameDropDown;
    });
    if (canBeSavedPartially) {
      const candidateNormalizeReferences = [];
      const normalizedJson = {};
      const tokenObject = {};
      let token;
      currentReference.forEach((reference) => {
        if (!reference.notificationSent) {
          tokenObject.referenceId = reference.id;
          tokenObject.dateNotified = reference.dateNotified;
          tokenObject.companyId = parseInt(
            reference.companyId ? reference.companyId : userData?.candidates[0].companyId
          );
          tokenObject.email = userData?.email;
          token = jsonwebtoken.sign(tokenObject, config.JWT_REDIRECT_SECRET_KEY, {
            expiresIn: "72h",
          });
        }
        normalizedJson.id = reference.id;
        normalizedJson.startDate = reference.startDate;
        normalizedJson.endDate = reference.endDate;
        normalizedJson.facilityName = reference.facilityName;
        normalizedJson.supervisorFirstName = reference.firstName;
        normalizedJson.supervisorLastName = reference.lastName;
        normalizedJson.supervisorTitle = reference.title;
        normalizedJson.supervisorEmail = reference.email;
        normalizedJson.supervisorPhone = reference.phone;
        normalizedJson.notificationSent = reference.notificationSent;
        normalizedJson.status = reference.status;
        normalizedJson.dateCompleted = reference.dateCompleted;
        normalizedJson.dateCreated = reference.dateCreated;
        normalizedJson.dateLastUpdated = reference.dateLastUpdated;
        normalizedJson.token = reference.notificationSent ? reference?.token : token;
        normalizedJson.profession = reference.profession;
        normalizedJson.specialty = reference.specialty;
        normalizedJson.facilityCityState = reference.facilityCityState;
        normalizedJson.employed = reference.employed;
        normalizedJson.isDeleted = reference.isDeleted || false;
        normalizedJson.dateNotified = reference.dateNotified;
        normalizedJson.referenceId = reference?.referenceId || null;
        normalizedJson.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
        normalizedJson.declinationReason = reference?.declinationReason;
        normalizedJson.responseOne = reference?.responseOne;
        normalizedJson.responseTwo = reference?.responseTwo;
        normalizedJson.responseThree = reference?.responseThree;
        normalizedJson.comments = reference?.comments;
        normalizedJson.contactConsent = reference?.contactConsent;
      });
      setCandidateReferences(normalizedJson);
      candidateNormalizeReferences.push(normalizedJson);
      reqObj = {
        event:
          candidateReferences && candidateReferences.length > 1
            ? "updateReference"
            : "addReference",
        email: userData?.email,
        companyId: currentReference[0].companyId
          ? currentReference[0].companyId
          : parseInt(userData?.candidates[0].companyId),
        recruiterId: UserID ? parseInt(UserID) : null,
        isDeleted: referencesIsDeleted,
        candidateReference: {
          meta: {
            lastVisitedPage: "4",
            completedSections: {
              references: true, //! validators ? true : false, [TODO]
            },
          },
          references: candidateNormalizeReferences,
        },
      };
    }
    return { reqObj, canBeSavedPartially };
  };

  const addReference = () => {
    setMode(APIMethods.ADD);
    setIsReadOnly(false);
    setIsReferenceFormOpen(true);
  };

  const handleContactConsentChange = () => {
    const toggleContactConsent = contactConsent.value === "no" ? "yes" : "no";
    setContactConsent({
      value: toggleContactConsent,
      errors: false,
      errorText: "",
    });
  };

  const handleEdit = (ref, index) => {
    setReferenceStatus(ref.status);
    setMode(APIMethods.EDIT);
    setEditIndex(index);
    setIsEdit(true);
    setIsReferenceFormOpen(true);
    const facilityDropdownVal =
      `${ref.facilityName ? ref.facilityName : "NA"}` +
      " - " +
      `${ref.startDate ? moment.utc(ref.startDate).format("MM/DD/YYYY") : "NA"}` +
      " - " +
      `${ref.endDate ? moment.utc(ref.endDate).format("MM/DD/YYYY") : "Present"}`;
    setFirstName({
      value: ref.supervisorFirstName,
      errors: false,
      errorText: "",
    });
    setLastName({
      value: ref.supervisorLastName,
      errors: false,
      errorText: "",
    });
    setTitle({
      value: ref.supervisorTitle,
      errors: false,
      errorText: "",
    });
    setFacilityNameDropDown({
      value: facilityDropdownVal,
      errors: false,
      errorText: "",
    });
    setEmail({
      value: ref.supervisorEmail,
      errors: false,
      errorText: "",
    });
    setPhone({
      value: ref.supervisorPhone,
      errors: false,
      errorText: "",
    });
    setFacilityName(ref.facilityName);
    setCompanyId(ref.companyId);
    setStartDate(ref.startDate);
    setEndDate(ref.endDate);
    setProfession(ref.profession);
    setSpecialty(ref.specialty);
    setFacilityLocation(ref.facilityCityState);
    setEmployed(ref.employed);
    setAdditionalComments(ref.comments);
    setContactConsent({
      value: ref.contactConsent,
      errors: false,
      errorText: "",
    });
    if (ref.declinationReason && ref.declinationReason !== "") {
      setDeclineQuestionnaire(true);
      setDeclinationReason(ref.declinationReason);
      setAttendanceStandards({
        value: "",
        errors: false,
        errorText: "",
      });
      setProfessionalStandards({
        value: "",
        errors: false,
        errorText: "",
      });
      setClinicalStandards({
        value: "",
        errors: false,
        errorText: "",
      });
    } else {
      setDeclineQuestionnaire(false);
      setDeclinationReason(ref.declinationReason);
      setAttendanceStandards({
        value: ref.responseOne,
        errors: false,
        errorText: "",
      });
      setProfessionalStandards({
        value: ref.responseTwo,
        errors: false,
        errorText: "",
      });
      setClinicalStandards({
        value: ref.responseThree,
        errors: false,
        errorText: "",
      });
    }
  };

  const editReferences = async () => {
    if (
      isSurveyQuestionsValidated() ||
      firstName.value === "" ||
      lastName.value === "" ||
      title.value === "" ||
      (email.value === "" && phone.value === "") ||
      (email.value === null && phone.value === null) ||
      (email.value === null && phone.value === "") ||
      (email.value === "" && phone.value === null) ||
      facilityNameDropDown.value === "" ||
      contactConsent.value === "no" ||
      contactConsent.value === "" ||
      firstName.errors ||
      lastName.errors ||
      email.errors ||
      phone.errors ||
      (declineQuestionnaire && declinationReason === "")
    ) {
      validationOnSaveAndEdit();
    } else {
      const record = {};
      referencesFields.forEach((field) => {
        record[field] = getFieldData(field);
      });
      const status = getStatus();
      record.facilityName = facilityName;
      record.startDate = startDate;
      record.endDate = endDate;
      record.status = status;
      record.companyId = companyId;
      record.dateLastUpdated = moment.utc().format();
      (record.declinationReason = declinationReason),
        (record.responseOne = attendanceStandards.value),
        (record.responseTwo = professionalStandards.value),
        (record.responseThree = clinicalStandards.value),
        (record.comments = additionalComments),
        (record.specialty = specialty);
      record.profession = profession;
      record.facilityCityState = facilityLocation;
      record.employed = employed;
      record.contactConsent = contactConsent.value;
      const referencesArr = candidateReferences;
      const reference = referencesArr.length >= editIndex ? referencesArr[editIndex] : null;
      if (reference) {
        record.id = reference.id;
        //! [TODO - need to handle subsequent edit]
        record.referenceId = reference?.referenceId || null;
        record.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
        record.token = reference?.token;
        record.notificationSent = !(
          (status.toLowerCase() === ReferencesStates.PENDING.toLowerCase() &&
            reference?.status.toLowerCase() !== status.toLowerCase()) ||
          (status.toLowerCase() === ReferencesStates.PENDING.toLowerCase() &&
            (reference?.supervisorEmail?.toLowerCase() !== record?.email?.toLowerCase() ||
              reference?.supervisorPhone !== record?.phone))
        );
        record.dateCompleted = status === ReferencesStates.COMPLETE ? moment.utc().format() : null;
        record.dateCreated = reference.dateCreated;
        record.dateNotified =
          status === ReferencesStates.PENDING ? moment.utc().format() : reference.dateNotified;
        const { reqObj } = normalizeReferenceJson([record]);
        referencesArr[editIndex] = reqObj?.candidateReference?.references[0];
        setCandidateReferences(referencesArr);
        handleSaveReferences(reqObj);
      }
      resetStates();
    }
  };

  const handleReadOnly = (ref, index) => {
    handleEdit(ref, index);
  };

  const handleDelete = (index, reference) => {
    candidateReferences.splice(index, 1);
    setCandidateReferences(candidateReferences);
    resetStates();
    if (candidateReferences.length === 0) {
      setIsReferenceFormOpen(false);
    }
    deleteReferences(reference);
  };

  const deleteReferences = async (reference) => {
    try {
      const candidateReference = [];
      const normalizedJson = {};
      normalizedJson.id = reference.id;
      normalizedJson.startDate = reference.startDate;
      normalizedJson.endDate = reference.endDate;
      normalizedJson.facilityName = reference.facilityName;
      normalizedJson.supervisorFirstName = reference.firstName;
      normalizedJson.supervisorLastName = reference.lastName;
      normalizedJson.supervisorTitle = reference.title;
      normalizedJson.supervisorEmail = reference.email;
      normalizedJson.supervisorPhone = reference.phone;
      normalizedJson.notificationSent = reference.notificationSent;
      normalizedJson.status = reference.status;
      normalizedJson.dateCompleted = reference.dateCompleted;
      normalizedJson.dateCreated = reference.dateCreated;
      normalizedJson.dateLastUpdated = reference.dateLastUpdated;
      normalizedJson.profession = reference.profession;
      normalizedJson.specialty = reference.specialty;
      normalizedJson.facilityCityState = reference.facilityCityState;
      normalizedJson.employed = reference.employed;
      normalizedJson.token = reference.token;
      normalizedJson.referenceId = reference?.referenceId || null;
      normalizedJson.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
      normalizedJson.declinationReason = reference?.declinationReason;
      normalizedJson.responseOne = reference?.responseOne;
      normalizedJson.responseTwo = reference?.responseTwo;
      normalizedJson.responseThree = reference?.responseThree;
      normalizedJson.comments = reference?.comments;
      candidateReference.push(normalizedJson);
      const reqObj = {
        email: userData?.email,
        companyId: reference.companyId
          ? reference.companyId
          : parseInt(userData?.candidates[0].companyId),
        isDeleted: referencesIsDeleted,
        recruiterId: UserID ? parseInt(UserID) : null,
        candidateReference: {
          meta: {
            lastVisitedPage: "4",
            completedSections: {
              references: candidateReferences.length > 0,
            },
          },
          references: candidateReference,
        },
      };
      await deleteReferenceFromDb({ variables: { referencesInput: reqObj } });
    } catch (error) {
      console.error("Error while deleting references:", error);
    }
  };

  const handleRefresh = async () => {
    try {
      const query = {};
      if (enableWorkHistory) {
        query.bullhornId = EntityID;
        query.email = userData?.email;
        query.companyId = userData?.candidates[0].companyId;
      } else {
        query.user = userData || null;
      }

      setIsDisabledSync(true);
      request(enableWorkHistory ? syncProfileDataFromBullhornQuery : syncProfileDataFromAbleQuery, query).then(
        (res) => {
          setIsDisabledSync(false);
        },
        (error) => {
          console.error("Error while fetching missing work history entries", error);
          setIsDisabledSync(false);
        }
      );
    } catch (error) {
      console.error("Error while fetching missing work history entries:", error);
    }
  };

  return (
    <>
      {/* Reference Form Code start */}
      <div>
        <Box className="formContainer">
          <div elevation={1} className="formContent">
            <Grid>
              {candidateReferences && candidateReferences.length === 0 && !isReferenceFormOpen && (
                <Grid justifyContent="center" className="noReferenceAdded">
                  <img src={referenceIcon} className="mb-4" />
                  <p className="mb-4">You do not have any references on this record yet</p>
                </Grid>
              )}
              <Grid>
                {candidateReferences &&
                  ((candidateReferences.length === 0 && isReferenceFormOpen) ||
                    candidateReferences.length > 0) && (
                    <Box className="titleContainer">
                      <Grid container spacing={1} mb={2}>
                        <Grid item xs={12} md={7}>
                          <Typography component="span" className="empNameTitle">
                            Employee Name{" "}
                          </Typography>
                          <Typography component="span" className="empName">
                            {`[ ${userData?.firstname ? userData.firstname : ""} ${
                              userData?.lastname ? userData.lastname : ""
                            } ]`}
                          </Typography>
                          <Typography component="span" className="empNameTitle ml-5">
                            BH ID{" "}
                          </Typography>
                          <Typography component="span" className="empCode">
                            {`[ ${EntityID || ""} ]`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {/* ReferenceTable Code  */}
                {candidateReferences && candidateReferences.length > 0 && (
                  <ReferencesTable
                    references={candidateReferences}
                    mode={mode}
                    handelEdit={handleEdit}
                    handelReadOnly={handleReadOnly}
                    handelDelete={handleDelete}
                    isEdit={isEdit}
                    editIndex={editIndex}
                  />
                )}
              </Grid>

              {!isReferenceFormOpen ? (
                <div className="textCenter">
                  <Button onClick={addReference} size="medium" variant="contained">
                    <AddIcon fontSize="small" className="addIconMargin" />
                    Add Reference
                  </Button>
                </div>
              ) : null}

              {isReferenceFormOpen ? (
                <Grid>
                  <div className="syncDataWrapper">
                    <div className="syncInfoIcon">
                      <InfoOutlinedIcon />
                    </div>

                    <p className="m-0 px-2">
                      Missing Work History entries from the “Facility & Employment Dates”? Click
                      button to sync.
                    </p>

                    <button
                      className="syncDataButton"
                      onClick={handleRefresh}
                      disabled={isDisabledSync}
                    >
                      {isDisabledSync ? (
                        <CircularProgress size={16} className="ml-0 mr-2 loadingBtnIcon" />
                      ) : (
                        <LoopOutlinedIcon className="ml-0 mr-2" />
                      )}{" "}
                      SYNC DATA
                    </button>
                  </div>
                  <Box className="formFields">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Grid item>
                        <Typography className="formSubHead">Candidate Info</Typography>
                      </Grid>
                      {referenceStatus === ReferencesStates.EXPIRED ? (
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={mode === APIMethods.ADD ? saveReferences : editReferences}
                          >
                            RESEND QUESTIONNAIRE
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth disabled={isReadOnly} className="customDropDown">
                          <InputLabel id="facility-select-label" className="selectLabel">
                            Facility & Employment Dates *
                          </InputLabel>
                          {facilityNameDropDown.errors ? (
                            <FormHelperText>{facilityNameDropDown.errorText}</FormHelperText>
                          ) : null}
                          <Select
                            fullWidth
                            className="input-select"
                            labelId="Facility-select-label"
                            id="Facility-select"
                            label="Facility & Employment Dates *"
                            value={facilityNameDropDown.value}
                            onChange={(e) => handleMultipleSelect(e, "facilityName")}
                          >
                            {facilityList.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {facilityNameDropDown.value ? (
                        <Grid item xs={12} md={6}>
                          <div className="professionLabel">Profession/Specialty</div>
                          <div className="professionText" disabled={isReadOnly}>
                            {profession && specialty
                              ? `${profession}/${specialty}`
                              : profession || specialty || ""}
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                  <Box className="formFields">
                    <Typography className="formSubHead">Manager/Supervisor Info</Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="outlined-required"
                          label="First Name"
                          className="input-text"
                          fullWidth
                          value={firstName.value}
                          error={!!firstName.errors}
                          onChange={(e) => handleChange(e, "firstName")}
                          helperText={firstName.errors ? firstName.errorText : ""}
                          type="TextField"
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Last Name"
                          className="input-text"
                          fullWidth
                          value={lastName.value}
                          onChange={(e) => handleChange(e, "lastName")}
                          error={!!lastName.errors}
                          helperText={lastName.errors ? lastName.errorText : ""}
                          type="TextField"
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth disabled={isReadOnly}>
                          <InputLabel className="selectLabel" />
                          <FormControl fullWidth disabled={isReadOnly} className="customDropDown">
                            <InputLabel className="selectLabel">Title *</InputLabel>
                            {title.errors ? (
                              <FormHelperText>{title.errorText}</FormHelperText>
                            ) : null}
                            <Select
                              required
                              label="Title"
                              value={title.value ? title.value : ""}
                              onChange={(e) => handleMultipleSelect(e, "title")}
                              className="input-select"
                              disabled={isReadOnly}
                            >
                              {titleList.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box component="div">
                          <fieldset className="customBox">
                            <legend className="emailAndPhoneLabel">
                              Please provide at least either email or phone.*
                            </legend>
                            <Grid container spacing={4}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="standard-basic"
                                  label="Email Address"
                                  variant="outlined"
                                  fullWidth
                                  type="TextField"
                                  value={email.value}
                                  error={!!email.errors}
                                  onChange={(e) => handleChange(e, "email")}
                                  helperText={email.errors ? email.errorText : ""}
                                  className="input-text normalText"
                                  disabled={isReadOnly}
                                />
                              </Grid>
                              <div className="addOrLabel" disabled={isReadOnly}>
                                AND/OR
                              </div>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="standard-basic"
                                  label="Phone"
                                  variant="outlined"
                                  fullWidth
                                  type="TextField"
                                  value={phone.value}
                                  error={!!phone.errors}
                                  onChange={(e) => handleChange(e, "phone")}
                                  helperText={phone.errors ? phone.errorText : ""}
                                  className="input-text"
                                  disabled={isReadOnly}
                                />
                              </Grid>
                            </Grid>
                          </fieldset>
                        </Box>
                        {emailPhoneErrors ? <FormHelperText>* Required</FormHelperText> : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} mt={4} style={{ display: "flex" }}>
                      <FormControlLabel
                        disabled={isReadOnly}
                        control={
                          <Checkbox
                            defaultChecked
                            onChange={() => handleContactConsentChange()}
                            checked={contactConsent.value === "yes"}
                          />
                        }
                        label="Permission to contact"
                      />
                      {contactConsent.errors ? (
                        <FormHelperText className="pt-2 pl-2">
                          {contactConsent.errorText}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                  </Box>
                  <Box className="formFields">
                    <Typography className="formSubHead">Reference Questionnaire</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <FormControlLabel
                          disabled={isReadOnly}
                          control={
                            <Checkbox
                              checked={declineQuestionnaire}
                              onChange={(event) => {
                                setDeclineQuestionnaire(event.target.checked);
                                setDeclinationReason("");
                                setAttendanceStandards({
                                  value: "",
                                  errors: false,
                                  errorText: "",
                                });
                                setProfessionalStandards({
                                  value: "",
                                  errors: false,
                                  errorText: "",
                                });
                                setClinicalStandards({
                                  value: "",
                                  errors: false,
                                  errorText: "",
                                });
                              }}
                            />
                          }
                          label="Decline Questionnaire"
                        />
                      </Grid>
                      <Grid item xs={12} md={5} mb={3}>
                        <FormControl fullWidth disabled={isReadOnly} className="customDropDown">
                          <InputLabel id="facility-select-label" className="selectLabel">
                            Reason for declining
                          </InputLabel>
                          {declineQuestionnaire && declinationReason === "" ? (
                            <FormHelperText>* Required</FormHelperText>
                          ) : null}
                          <Select
                            className="input-select"
                            labelId="Facility-select-label"
                            id="Facility-select"
                            label="Reason for declining"
                            value={declinationReason}
                            onChange={(event) => {
                              setDeclinationReason(event.target.value);
                              setDeclineQuestionnaire(true);
                              setAttendanceStandards({
                                value: "",
                                errors: false,
                                errorText: "",
                              });
                              setProfessionalStandards({
                                value: "",
                                errors: false,
                                errorText: "",
                              });
                              setClinicalStandards({
                                value: "",
                                errors: false,
                                errorText: "",
                              });
                            }}
                          >
                            <MenuItem value={"I don't know the employee"}>
                              I don't know the employee
                            </MenuItem>
                            <MenuItem
                              value={"I don't wish to provide a reference for the employee"}
                            >
                              I don't wish to provide a reference for the employee
                            </MenuItem>
                            <MenuItem value="I didn’t manage this employee">
                              I didn’t manage this employee
                            </MenuItem>
                            <MenuItem value="Against facility policy">
                              Against facility policy
                            </MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={declineQuestionnaire ? "disableGrid" : ""}
                      >
                        <Grid className="optionBox">
                          <Grid container spacing={2} className="options">
                            <Grid item disabled={isReadOnly}>
                              <Typography component="span">
                                Did the employee meet punctuality and attendance standards?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={attendanceStandards.value}
                                onChange={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleChange(event, "attendanceStandards");
                                  }
                                }}
                                onClick={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleClick(event, "attendanceStandards");
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="No"
                                  control={<Radio name="checkedPunctuality" size="small" />}
                                  label="No"
                                  disabled={isReadOnly}
                                />
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio name="checkedPunctuality" size="small" />}
                                  label="Yes"
                                  disabled={isReadOnly}
                                />
                                <InputLabel className="selectLabel">
                                  {surveyQuestionsError && attendanceStandards.value === "" ? (
                                    <p className="errorText">{requiredErrMsg}</p>
                                  ) : (
                                    ""
                                  )}
                                </InputLabel>
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={declineQuestionnaire ? "disableGrid" : ""}
                      >
                        <Grid className="optionBox">
                          <Grid container spacing={2} className="options">
                            <Grid item disabled={isReadOnly}>
                              <Typography component="span">
                                Did the employee meet professionals standards?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={professionalStandards.value}
                                onChange={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleChange(event, "professionalStandards");
                                  }
                                }}
                                onClick={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleClick(event, "professionalStandards");
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="No"
                                  control={<Radio name="checkedProfessionals" size="small" />}
                                  label="No"
                                  disabled={isReadOnly}
                                />
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio name="checkedProfessionals" size="small" />}
                                  label="Yes"
                                  disabled={isReadOnly}
                                />
                                <InputLabel className="selectLabel">
                                  {surveyQuestionsError && professionalStandards.value === "" ? (
                                    <p className="errorText">{requiredErrMsg}</p>
                                  ) : (
                                    ""
                                  )}
                                </InputLabel>
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={declineQuestionnaire ? "disableGrid" : ""}
                      >
                        <Grid className="optionBox">
                          <Grid container spacing={2} className="options">
                            <Grid item disabled={isReadOnly}>
                              <Typography component="span">
                                Did the employee meet clinical standards?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={clinicalStandards.value}
                                onChange={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleChange(event, "clinicalStandards");
                                  }
                                }}
                                onClick={(event) => {
                                  if (!isReadOnly) {
                                    radioBtnHandleClick(event, "clinicalStandards");
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="No"
                                  control={<Radio name="checkedClinical" size="small" />}
                                  label="No"
                                  disabled={isReadOnly}
                                />
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio name="checkedClinical" size="small" />}
                                  label="Yes"
                                  disabled={isReadOnly}
                                />
                                <InputLabel className="selectLabel">
                                  {surveyQuestionsError && clinicalStandards.value === "" ? (
                                    <p className="errorText">{requiredErrMsg}</p>
                                  ) : (
                                    ""
                                  )}
                                </InputLabel>
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Additional comments? (Optional)"
                          multiline
                          rows={2}
                          fullWidth
                          value={additionalComments}
                          className="input-text input-textarea"
                          onChange={(event) => setAdditionalComments(event.target.value)}
                          disabled={isReadOnly}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <CardActions>
                    <Grid container justifyContent="center" mb={4}>
                      {isReadOnly ? (
                        <Grid>
                          <Button variant="outlined" onClick={cancelUpdate}>
                            Close
                          </Button>
                        </Grid>
                      ) : (
                        <>
                          <Grid item mr={3}>
                            <Button
                              variant="contained"
                              onClick={mode === APIMethods.ADD ? saveReferences : editReferences}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid>
                            <Button variant="outlined" onClick={cancelUpdate}>
                              Cancel
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardActions>
                </Grid>
              ) : null}
              <Grid>
                <p />
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
      {/* Reference Form Code end */}
    </>
  );
};
export default ReferenceForm;
