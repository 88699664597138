import Image from "react-bootstrap/Image";
import XperienceLogo from "src/imagesV2/xperience_rebrand_logo.svg";
import XperienceLogoMobile from "src/imagesV2/xperience_rebrand_logo_mobile.svg";

interface LogoProps {
  className?: string;
  mobile?: boolean;
}

const Logo = ({ className, mobile }: LogoProps) => (
  <Image
    src={mobile ? XperienceLogoMobile : XperienceLogo}
    className={`mainLogo ${className ?? ""}`}
  />
);

Logo.defaultProps = {
  className: "",
  mobile: false,
};

export default Logo;
