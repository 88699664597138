import { withTranslation, TFunction } from "react-i18next";
import styles from "src/components/common/MainContent/JobDescription/PayBreakdown/PayBreakdown.module.scss";
import { isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";

interface IPaymentBreakdown {
  t: TFunction<"translation", undefined>;
  jobPayDetails?: {
    salaryMax: number;
    salaryExpenseReimbursementWeekly: number;
    hoursPerWeek: number;
  };
}

// TODO: Real rate calculation should be done in WEBONE-16865 and WEBONE-16069
const calculateRates = (jobDetails) => {
  return {
    grossPayRate: jobDetails?.salaryMax?.toLocaleString("en-US"),
    wagesMaxRate: (jobDetails?.salaryHourly * jobDetails?.hoursPerWeek)?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    wagesStandardRate: jobDetails?.salaryHourly?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    wagesMaxFrecuencyRate: jobDetails?.hoursPerWeek?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    }),
    lodgingMaxRate: (jobDetails?.salaryLodgingDaily * 7)?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    lodgingFrecuency: 7,
    lodgingStandardRate: jobDetails?.salaryLodgingDaily?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    mealsMaxRate: (jobDetails?.salaryMealsDaily * 7)?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    mealsStandardRate: jobDetails?.salaryMealsDaily?.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    mealsFrecuency: 7,
  };
};

const PaymentBreakdown = ({ t, jobPayDetails }: IPaymentBreakdown) => {
  const calculatedRate = calculateRates(jobPayDetails);
  const wagesStandardRateDescription = isMobile
    ? `$${calculatedRate.wagesStandardRate}${t("jobs.payBreakdown.wages.minimunFrecuency")} x `
    : `$${calculatedRate.wagesStandardRate}${t("jobs.payBreakdown.wages.minimunFrecuency")} x ${
        calculatedRate.wagesMaxFrecuencyRate
      } ${t("jobs.payBreakdown.wages.maxFrecuency")}`;
  const wagesMaxRateDescription = `$${calculatedRate.wagesMaxRate}${t(
    "jobs.payBreakdown.maxFrecuency"
  )}`;

  const lodgingStandardRateDescription = isMobile
  ? `$${calculatedRate.lodgingStandardRate}${t("jobs.payBreakdown.wages.minimunFrecuency")} x `:
  `$${calculatedRate.lodgingStandardRate}${
    !isMobile ? t("jobs.payBreakdown.singleStandardFrecuency") : ""
  } x ${calculatedRate.lodgingFrecuency} ${t("jobs.payBreakdown.standardFrecuency")}`;
  const lodgingMaxRateDescription = `$${calculatedRate.lodgingMaxRate}${t(
    "jobs.payBreakdown.maxFrecuency"
  )}`;

  const mealsStandardRateDescription = isMobile
  ? `$${calculatedRate.mealsStandardRate}${t("jobs.payBreakdown.wages.minimunFrecuency")} x `:
  `$${calculatedRate.mealsStandardRate}${!isMobile ? t("jobs.payBreakdown.singleStandardFrecuency") : ""
  } x ${calculatedRate.mealsFrecuency} ${t("jobs.payBreakdown.standardFrecuency")}`;
  const mealsMaxRateDescription = `$${calculatedRate.mealsMaxRate}${t(
    "jobs.payBreakdown.maxFrecuency"
  )}`;

  return (
    <>
      <div className={styles.Frame}>
        <div className={styles.GrossPayRateFrame}>
          <span className={styles.GrossPayTitle}>{t("jobs.payBreakdown.grossPayRateTtitle")}</span>
          <div>
            <span className={styles.GrossPayAmount}>${calculatedRate.grossPayRate}</span>
          </div>
        </div>

        <div className={styles.GrossPayWageFrame}>
          <div className={styles.GrossPayEntryWage}>
            <div className={styles.GrossPayEntryWeekFrame}>
              <Col lg={8} className="GrossPayWageTitle"><span className={`${styles.GrossPayWageName} ${styles.GrossPayWageTitle} ${styles.GrossPayWageMaxRateRight}`}>{t("jobs.payBreakdown.wages.title")}</span></Col>
              
              <Col lg={4} className="standardRate">
                <Row className="GrossPayWageRate">
                  <span className={`${styles.GrossPayWageBaseRate} ${styles.GrossPayWageRate}`}>
                    {wagesStandardRateDescription}
                  </span>
                </Row>
                
                {isMobile && (
                  <Row>
                    <span className={`${styles.GrossPayWageBaseRate} ${styles.hrsFrequency}`}>
                      {calculatedRate.wagesMaxFrecuencyRate}
                      {t("jobs.payBreakdown.wages.maxFrecuency")}
                    </span>
                  </Row>
                )}
              </Col>
            </div>
            <div className={styles.GrossPayWageMaxRateFrame}>
              <span className={styles.GrossPayWageMaxRate}>{wagesMaxRateDescription}</span>
            </div>
          </div>
          <div className={styles.GrossPayEntryLodging}>
            <div className={styles.GrossPayEntryWeekFrame}>
              <span className={styles.GrossPayWageName}>{t("jobs.payBreakdown.lodgingTitle")}</span>
              <Col  className="standardRate">
                <Row>
                  <span className={styles.GrossPayWageBaseRate}>
                    {lodgingStandardRateDescription}
                  </span>
                </Row>
                {isMobile && (
                  <Row>
                    <span className={`${styles.GrossPayWageBaseRate} ${styles.hrsFrequency}`}>
                      {calculatedRate.lodgingFrecuency}
                      {t("jobs.payBreakdown.standardFrecuency")}
                    </span>
                  </Row>
                )}
              </Col>
            </div>
            <div className={styles.GrossPayWageMaxRateFrame}>
              <span className={styles.GrossPayWageMaxRate}>{lodgingMaxRateDescription}</span>
            </div>
          </div>
          <div className={styles.GrossPayEntryMeals}>
            <div className={styles.GrossPayEntryWeekFrame}>
              <span className={styles.GrossPayWageName}>{t("jobs.payBreakdown.mealsTitle")}</span>
              <Col  className="standardRate">
                <Row>
                  <span className={styles.GrossPayWageBaseRate}>
                    {mealsStandardRateDescription}
                  </span>
                </Row>
                {isMobile && (
                  <Row>
                    <span className={`${styles.GrossPayWageBaseRate} ${styles.hrsFrequency}`}>
                      {calculatedRate.mealsFrecuency}
                      {t("jobs.payBreakdown.standardFrecuency")}
                    </span>
                  </Row>
                )}
              </Col>
            </div>
            <div className={styles.GrossPayWageMaxRateFrame}>
              <span className={styles.GrossPayWageMaxRate}>{mealsMaxRateDescription}</span>
            </div>
          </div>
        </div>
        {isMobile && <div className={styles.PayBreakdownDisclaimerFrame}>
        <span className={styles.PayBreakdownDisclaimer}>{t("jobs.payBreakdown.disclaimer")}</span>
      </div>}
      </div>
      {!isMobile && <div className={styles.PayBreakdownDisclaimerFrame}>
        <span className={styles.PayBreakdownDisclaimer}>{t("jobs.payBreakdown.disclaimer")}</span>
      </div>}
      
    </>
  );
};

PaymentBreakdown.defaultProps = {
  jobPayDetails: {
    salaryMax: 0,
    salaryExpenseReimbursementWeekly: 0,
    hoursPerWeek: 0,
  },
};

export default withTranslation()(PaymentBreakdown);
