import React, { Component } from "react";
import { isLoggedIn, getAccessToken, getRefreshToken } from "axios-jwt";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  saveTokenInLocalStorageAndFillState,
  loginStatus,
  updateJobPreferencesFlag,
} from "../../redux/auth/action";
import Dashboard from "../common/MainContent/Home/Dashboard";
import config from "../../config";
import SomethingWentWrong from "../common/MainContent/Home/SomethingWentWrong";
import Analytics from "../../services/Analytics";
import analyticsSegmentIdentify from "../../services/SegmentIdentify";

class HomePage extends Component {
  constructor(props) {
    super(props);
    const referrer = document.referrer;
    this.state = {
      verifyEmailPopUp: false,
      passwordSuccessPopUp: false,
    };

    if (
      localStorage.getItem("historyPathName") &&
      localStorage.getItem("historyPathName").includes("/auth/relay")
    )
      this.triggerRecruitics();
  }

  async componentDidMount() {
    if (
      localStorage.getItem("historyPathName") &&
      localStorage.getItem("historyPathName").includes("/auth/relay")
    ) {
      this.props.updateJobPreferencesFlag(true);
      if (document.referrer.includes("register")) {
        await this.props.loginStatus(true, "User Sign Up");
        if (this.props.signUpSuccess) {
          const segmentEventName = this.props.t("segment.userCreated");
          const segmentEventProps = {
            id: this.props.userId,
            userName: this.props.email,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            email: this.props.email,
            profession: this.props.profession,
            specialty: this.props.specialty,
            company: this.props.company.code,
            origin: document.referrer,
          };
          Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
        }
      }
    }
    if (!this.props.isCandidateListEmpty) {
      if (isLoggedIn() && this.props.isAuthenticated === false) {
        this.props.resyncReduxState(
          getAccessToken(),
          getRefreshToken(),
          this.props.company.id,
          null
        );
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const popUpFlag = urlParams.get("verifyPopUp");
    const passwordpopUp = urlParams.get("passwordCreated");
    if (popUpFlag === "true") {
      this.setState({
        verifyEmailPopUp: true,
      });
    }
    if (passwordpopUp === "true") {
      this.setState({
        passwordSuccessPopUp: true,
      });
    }
  }

  //Trigger recruitics for WCM new user job apply
  triggerRecruitics() {
    if (sessionStorage.getItem("isRecruiticsCalled") === null) {
      sessionStorage.setItem("isRecruiticsCalled", true);
      setTimeout(() => {
        window.rx.trigger({
          triggerName: "apply",
          applyFlowId: window.rx.getViewerId(),
        });
      }, 10000);
    }
  }

  render() {
    /* Change for resolving page reload  glitch */
    if (this.props.initLoading) {
      return null;
    } else if (this.props.isCandidateListEmpty && !this.props.initLoading) {
      return <SomethingWentWrong errorCode={config.ERROR_CODES.NO_CANDIDATES} />;
    }
    return (
      <Dashboard
        verifyEmailPopUp={this.state.verifyEmailPopUp}
        passwordSuccessPopUp={this.state.passwordSuccessPopUp}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resyncReduxState: (idToken, refreshToken, companyId, path) =>
    dispatch(saveTokenInLocalStorageAndFillState(idToken, refreshToken, companyId, path)),
  loginStatus: (value, origin) => dispatch(loginStatus(value, origin)),
  updateJobPreferencesFlag: (value) => dispatch(updateJobPreferencesFlag(value)),
});

const mapStateToProps = (state) => ({
  signUpSuccess: state.signUp.signUpSuccess,
  isAuthenticated: state.auth.isAuthenticated,
  applicantStatus: state.candidate.applicantStatus,
  signUpSuccessResponse: state.signUp.signUpSuccessResponse,
  company: state.company.company,
  applicantId: state.candidate.applicantId,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  initLoading: state.auth.loading,
  portalComplete: state.onBoarding.portalComplete,
  isFirstLogin: state.auth.isFirstLogin,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  profession: state.auth.candidateProfession,
  specialty: state.auth.candidateSpecialties,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage));
